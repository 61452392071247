// src/PrimaryPools.ts
import { BigNumber as BigNumber2 } from "@ethersproject/bignumber";

// src/Utils.ts
import { BigNumber } from "@ethersproject/bignumber";
function ASSERT(f, t) {
  if (process.env.NODE_ENV !== "production") {
    if (!f() && t)
      console.error(t);
  }
}
var DEBUG_MODE = false;
function DEBUG(f) {
  if (DEBUG_MODE)
    f();
}
function DEBUG_MODE_ON(on) {
  DEBUG_MODE = on;
}
function closeValues(a, b, accuracy, logInfoIfFalse = "") {
  if (accuracy === 0)
    return a === b;
  if (Math.abs(a) < 1 / accuracy)
    return Math.abs(a - b) <= 10;
  if (Math.abs(b) < 1 / accuracy)
    return Math.abs(a - b) <= 10;
  const res = Math.abs(a / b - 1) < accuracy;
  if (!res) {
    console.log("Expected close: ", a, b, accuracy, logInfoIfFalse);
  }
  return res;
}
function calcSquareEquation(a, b, c) {
  const D = b * b - 4 * a * c;
  console.assert(D >= 0, `Discriminant is negative! ${a} ${b} ${c}`);
  const sqrtD = Math.sqrt(D);
  return [(-b - sqrtD) / 2 / a, (-b + sqrtD) / 2 / a];
}
function revertPositive(f, out, hint = 1) {
  try {
    if (out <= f(0))
      return 0;
    let min, max;
    if (f(hint) > out) {
      min = hint / 2;
      while (f(min) > out)
        min /= 2;
      max = min * 2;
    } else {
      max = hint * 2;
      while (f(max) < out)
        max *= 2;
      min = max / 2;
    }
    while (max / min - 1 > 1e-4) {
      const x0 = (min + max) / 2;
      const y0 = f(x0);
      if (out === y0)
        return x0;
      if (out < y0)
        max = x0;
      else
        min = x0;
    }
    return (min + max) / 2;
  } catch (e) {
    return 0;
  }
}
function getBigNumber(value) {
  const v = Math.abs(value);
  if (v < Number.MAX_SAFE_INTEGER)
    return BigNumber.from(Math.round(value));
  const exp = Math.floor(Math.log(v) / Math.LN2);
  console.assert(exp >= 51, "Internal Error 314");
  const shift = exp - 51;
  const mant = Math.round(v / Math.pow(2, shift));
  const res = BigNumber.from(mant).mul(BigNumber.from(2).pow(shift));
  return value > 0 ? res : res.mul(-1);
}

// src/PrimaryPools.ts
var TYPICAL_SWAP_GAS_COST = 6e4;
var TYPICAL_MINIMAL_LIQUIDITY = 1e3;
function setTokenId(...tokens) {
  tokens.forEach((t) => {
    if (!t.tokenId)
      t.tokenId = `${t.address}_${t.chainId}`;
  });
}
var RPool = class {
  address;
  token0;
  token1;
  fee;
  reserve0;
  reserve1;
  minLiquidity;
  swapGasCost;
  constructor(address, token0, token1, fee, reserve0, reserve1, minLiquidity = TYPICAL_MINIMAL_LIQUIDITY, swapGasCost = TYPICAL_SWAP_GAS_COST) {
    this.address = address;
    this.token0 = token0;
    this.token1 = token1;
    setTokenId(this.token0, this.token1);
    this.fee = fee;
    this.minLiquidity = minLiquidity;
    this.swapGasCost = swapGasCost;
    this.reserve0 = reserve0;
    this.reserve1 = reserve1;
  }
  updateReserves(res0, res1) {
    this.reserve0 = res0;
    this.reserve1 = res1;
  }
  getReserve0() {
    return this.reserve0;
  }
  getReserve1() {
    return this.reserve1;
  }
  granularity0() {
    return 1;
  }
  granularity1() {
    return 1;
  }
  alwaysAppropriateForPricing() {
    return false;
  }
};
var ConstantProductRPool = class extends RPool {
  reserve0Number;
  reserve1Number;
  constructor(address, token0, token1, fee, reserve0, reserve1) {
    super(address, token0, token1, fee, reserve0, reserve1);
    this.reserve0Number = parseInt(reserve0.toString());
    this.reserve1Number = parseInt(reserve1.toString());
  }
  updateReserves(res0, res1) {
    this.reserve0 = res0;
    this.reserve0Number = parseInt(res0.toString());
    this.reserve1 = res1;
    this.reserve1Number = parseInt(res1.toString());
  }
  calcOutByIn(amountIn, direction) {
    const x = direction ? this.reserve0Number : this.reserve1Number;
    const y = direction ? this.reserve1Number : this.reserve0Number;
    const out = y * amountIn / (x / (1 - this.fee) + amountIn);
    if (y - out < this.minLiquidity)
      throw "CP OutOfLiquidity";
    return { out, gasSpent: this.swapGasCost };
  }
  calcInByOut(amountOut, direction) {
    const x = direction ? this.reserve0Number : this.reserve1Number;
    const y = direction ? this.reserve1Number : this.reserve0Number;
    if (y - amountOut < this.minLiquidity)
      return { inp: Number.POSITIVE_INFINITY, gasSpent: this.swapGasCost };
    const input = x * amountOut / (1 - this.fee) / (y - amountOut);
    return { inp: input, gasSpent: this.swapGasCost };
  }
  calcCurrentPriceWithoutFee(direction) {
    return this.calcPrice(0, direction, false);
  }
  calcPrice(amountIn, direction, takeFeeIntoAccount) {
    const x = direction ? this.reserve0Number : this.reserve1Number;
    const y = direction ? this.reserve1Number : this.reserve0Number;
    const oneMinusFee = takeFeeIntoAccount ? 1 - this.fee : 1;
    const xf = x / oneMinusFee;
    return y * xf / (xf + amountIn) / (xf + amountIn);
  }
  calcInputByPrice(price, direction, takeFeeIntoAccount) {
    const x = direction ? this.reserve0Number : this.reserve1Number;
    const y = direction ? this.reserve1Number : this.reserve0Number;
    const oneMinusFee = takeFeeIntoAccount ? 1 - this.fee : 1;
    const xf = x / oneMinusFee;
    return Math.sqrt(y * xf * price) - xf;
  }
  getLiquidity() {
    return Math.sqrt(this.reserve0Number * this.reserve1Number);
  }
};
var HybridRPool = class extends RPool {
  A;
  A_PRECISION = 100;
  D;
  constructor(address, token0, token1, fee, A, reserve0, reserve1) {
    super(address, token0, token1, fee, reserve0, reserve1);
    this.A = A;
    this.D = BigNumber2.from(0);
  }
  updateReserves(res0, res1) {
    this.D = BigNumber2.from(0);
    this.reserve0 = res0;
    this.reserve1 = res1;
  }
  computeLiquidity() {
    if (!this.D.eq(0))
      return this.D;
    const r0 = this.reserve0;
    const r1 = this.reserve1;
    if (r0.isZero() && r1.isZero())
      return BigNumber2.from(0);
    const s = r0.add(r1);
    const nA = BigNumber2.from(this.A * 2);
    let prevD;
    let D = s;
    for (let i = 0; i < 256; i++) {
      const dP = D.mul(D).div(r0).mul(D).div(r1).div(4);
      prevD = D;
      D = nA.mul(s).div(this.A_PRECISION).add(dP.mul(2)).mul(D).div(nA.div(this.A_PRECISION).sub(1).mul(D).add(dP.mul(3)));
      if (D.sub(prevD).abs().lte(1)) {
        break;
      }
    }
    this.D = D;
    return D;
  }
  computeY(x) {
    const D = this.computeLiquidity();
    const nA = this.A * 2;
    const c = D.mul(D).div(x.mul(2)).mul(D).div(nA * 2 / this.A_PRECISION);
    const b = D.mul(this.A_PRECISION).div(nA).add(x);
    let yPrev;
    let y = D;
    for (let i = 0; i < 256; i++) {
      yPrev = y;
      y = y.mul(y).add(c).div(y.mul(2).add(b).sub(D));
      if (y.sub(yPrev).abs().lte(1)) {
        break;
      }
    }
    return y;
  }
  calcOutByIn(amountIn, direction) {
    const xBN = direction ? this.reserve0 : this.reserve1;
    const yBN = direction ? this.reserve1 : this.reserve0;
    const xNewBN = xBN.add(getBigNumber(amountIn * (1 - this.fee)));
    const yNewBN = this.computeY(xNewBN);
    const dy = parseInt(yBN.sub(yNewBN).toString());
    if (parseInt(yNewBN.toString()) < this.minLiquidity)
      throw "Hybrid OutOfLiquidity";
    return { out: dy, gasSpent: this.swapGasCost };
  }
  calcInByOut(amountOut, direction) {
    const xBN = direction ? this.reserve0 : this.reserve1;
    const yBN = direction ? this.reserve1 : this.reserve0;
    let yNewBN = yBN.sub(getBigNumber(amountOut));
    if (yNewBN.lt(1))
      yNewBN = BigNumber2.from(1);
    const xNewBN = this.computeY(yNewBN);
    const input = Math.round(parseInt(xNewBN.sub(xBN).toString()) / (1 - this.fee));
    return { inp: input, gasSpent: this.swapGasCost };
  }
  calcCurrentPriceWithoutFee(direction) {
    return this.calcPrice(0, direction, false);
  }
  calcPrice(amountIn, direction, takeFeeIntoAccount) {
    const xBN = direction ? this.reserve0 : this.reserve1;
    const x = parseInt(xBN.toString());
    const oneMinusFee = takeFeeIntoAccount ? 1 - this.fee : 1;
    const D = parseInt(this.computeLiquidity().toString());
    const A = this.A / this.A_PRECISION;
    const xI = x + amountIn;
    const b = 4 * A * xI + D - 4 * A * D;
    const ac4 = D * D * D / xI;
    const Ds = Math.sqrt(b * b + 4 * A * ac4);
    const res = (0.5 - (2 * b - ac4 / xI) / Ds / 4) * oneMinusFee;
    return res;
  }
  calcInputByPrice(price, direction, takeFeeIntoAccount, hint = 1) {
    return revertPositive((x) => 1 / this.calcPrice(x, direction, takeFeeIntoAccount), price, hint);
  }
};

// src/BridgeBento.ts
var BENTO_MINIMUM_SHARE_BALANCE = 1e3;
var BRIDGING_GAS_COST = 6e4;
var BridgeBento = class extends RPool {
  elastic;
  base;
  freeLiquidity;
  constructor(address, tokenEthereum, tokenBento, elastic, base, freeLiquidity) {
    super(address, tokenEthereum, tokenBento, 0, elastic, base, BENTO_MINIMUM_SHARE_BALANCE, BRIDGING_GAS_COST);
    this.elastic = parseInt(elastic.toString());
    this.base = parseInt(base.toString());
    this.freeLiquidity = freeLiquidity === void 0 ? void 0 : parseInt(freeLiquidity.toString());
  }
  updateReserves(elastic, base) {
    this.reserve0 = elastic;
    this.elastic = parseInt(elastic.toString());
    this.reserve1 = base;
    this.base = parseInt(base.toString());
  }
  calcOutByIn(amountIn, direction) {
    let out;
    if (direction == true) {
      if (this.elastic == 0) {
        out = amountIn;
      } else {
        out = amountIn * this.base / this.elastic;
      }
    } else {
      if (this.base == 0) {
        out = amountIn;
      } else {
        out = amountIn * this.elastic / this.base;
      }
      if (this.freeLiquidity !== void 0 && out > this.freeLiquidity)
        throw new Error("OutOfLiquidity BridgeBento");
    }
    return { out, gasSpent: this.swapGasCost };
  }
  calcInByOut(amountOut, direction) {
    let inp;
    if (direction == true) {
      if (this.elastic == 0) {
        inp = amountOut;
      } else {
        if (this.base == 0) {
          inp = Number.POSITIVE_INFINITY;
        } else {
          inp = amountOut * this.elastic / this.base;
        }
      }
    } else {
      if (this.freeLiquidity !== void 0 && amountOut > this.freeLiquidity)
        inp = Number.POSITIVE_INFINITY;
      else {
        if (this.base == 0) {
          inp = amountOut;
        } else {
          if (this.elastic == 0) {
            inp = Number.POSITIVE_INFINITY;
          } else {
            inp = amountOut * this.base / this.elastic;
          }
        }
      }
    }
    return { inp, gasSpent: this.swapGasCost };
  }
  calcCurrentPriceWithoutFee(direction) {
    if (direction == true) {
      if (this.elastic == 0) {
        return 1;
      } else {
        return this.base / this.elastic;
      }
    } else {
      if (this.base == 0) {
        return 1;
      } else {
        return this.elastic / this.base;
      }
    }
  }
};

// src/BridgeBidirectionalUnlimited.ts
import { BigNumber as BigNumber3 } from "@ethersproject/bignumber";
var BridgeUnlimited = class extends RPool {
  constructor(address, token0, token1, fee, swapGasCost = 15e4) {
    super(address, token0, token1, fee, BigNumber3.from(-1), BigNumber3.from(-1), 0, swapGasCost);
  }
  calcOutByIn(amountIn, _direction) {
    return { out: amountIn * (1 - this.fee), gasSpent: this.swapGasCost };
  }
  calcInByOut(amountOut, _direction) {
    return { inp: amountOut / (1 - this.fee), gasSpent: this.swapGasCost };
  }
  calcCurrentPriceWithoutFee(_direction) {
    return 1;
  }
  alwaysAppropriateForPricing() {
    return true;
  }
};

// src/CLPool.ts
import { BigNumber as BigNumber4 } from "@ethersproject/bignumber";
var CL_MIN_TICK = -887272;
var CL_MAX_TICK = -CL_MIN_TICK - 1;
var ZERO = BigNumber4.from(0);
var c01 = BigNumber4.from("0xfffcb933bd6fad37aa2d162d1a594001");
var c02 = BigNumber4.from("0x100000000000000000000000000000000");
var c03 = BigNumber4.from("0xfff97272373d413259a46990580e213a");
var c04 = BigNumber4.from("0xfff2e50f5f656932ef12357cf3c7fdcc");
var c05 = BigNumber4.from("0xffe5caca7e10e4e61c3624eaa0941cd0");
var c06 = BigNumber4.from("0xffcb9843d60f6159c9db58835c926644");
var c07 = BigNumber4.from("0xff973b41fa98c081472e6896dfb254c0");
var c08 = BigNumber4.from("0xff2ea16466c96a3843ec78b326b52861");
var c09 = BigNumber4.from("0xfe5dee046a99a2a811c461f1969c3053");
var c10 = BigNumber4.from("0xfcbe86c7900a88aedcffc83b479aa3a4");
var c11 = BigNumber4.from("0xf987a7253ac413176f2b074cf7815e54");
var c12 = BigNumber4.from("0xf3392b0822b70005940c7a398e4b70f3");
var c13 = BigNumber4.from("0xe7159475a2c29b7443b29c7fa6e889d9");
var c14 = BigNumber4.from("0xd097f3bdfd2022b8845ad8f792aa5825");
var c15 = BigNumber4.from("0xa9f746462d870fdf8a65dc1f90e061e5");
var c16 = BigNumber4.from("0x70d869a156d2a1b890bb3df62baf32f7");
var c17 = BigNumber4.from("0x31be135f97d08fd981231505542fcfa6");
var c18 = BigNumber4.from("0x9aa508b5b7a84e1c677de54f3e99bc9");
var c19 = BigNumber4.from("0x5d6af8dedb81196699c329225ee604");
var c20 = BigNumber4.from("0x2216e584f5fa1ea926041bedfe98");
var c21 = BigNumber4.from("0x48a170391f7dc42444e8fa2");
var max256 = BigNumber4.from(2).pow(256).sub(1);
function getSqrtRatioAtTick(tick) {
  const absTick = Math.abs(tick);
  let ratio = (absTick & 1) != 0 ? c01 : c02;
  if ((absTick & 2) != 0)
    ratio = ratio.mul(c03).shr(128);
  if ((absTick & 4) != 0)
    ratio = ratio.mul(c04).shr(128);
  if ((absTick & 8) != 0)
    ratio = ratio.mul(c05).shr(128);
  if ((absTick & 16) != 0)
    ratio = ratio.mul(c06).shr(128);
  if ((absTick & 32) != 0)
    ratio = ratio.mul(c07).shr(128);
  if ((absTick & 64) != 0)
    ratio = ratio.mul(c08).shr(128);
  if ((absTick & 128) != 0)
    ratio = ratio.mul(c09).shr(128);
  if ((absTick & 256) != 0)
    ratio = ratio.mul(c10).shr(128);
  if ((absTick & 512) != 0)
    ratio = ratio.mul(c11).shr(128);
  if ((absTick & 1024) != 0)
    ratio = ratio.mul(c12).shr(128);
  if ((absTick & 2048) != 0)
    ratio = ratio.mul(c13).shr(128);
  if ((absTick & 4096) != 0)
    ratio = ratio.mul(c14).shr(128);
  if ((absTick & 8192) != 0)
    ratio = ratio.mul(c15).shr(128);
  if ((absTick & 16384) != 0)
    ratio = ratio.mul(c16).shr(128);
  if ((absTick & 32768) != 0)
    ratio = ratio.mul(c17).shr(128);
  if ((absTick & 65536) != 0)
    ratio = ratio.mul(c18).shr(128);
  if ((absTick & 131072) != 0)
    ratio = ratio.mul(c19).shr(128);
  if ((absTick & 262144) != 0)
    ratio = ratio.mul(c20).shr(128);
  if ((absTick & 524288) != 0)
    ratio = ratio.mul(c21).shr(128);
  if (tick > 0)
    ratio = max256.div(ratio);
  return ratio.shr(32);
}
var two96 = Math.pow(2, 96);
var CLRPool = class extends RPool {
  tickSpacing;
  liquidity;
  sqrtPriceX96;
  nearestTick;
  ticks;
  constructor(address, token0, token1, fee, tickSpacing, reserve0, reserve1, liquidity, sqrtPriceX96, nearestTick, ticks) {
    super(address, token0, token1, fee, reserve0, reserve1, TYPICAL_MINIMAL_LIQUIDITY, TYPICAL_SWAP_GAS_COST);
    this.tickSpacing = tickSpacing;
    this.liquidity = liquidity;
    this.sqrtPriceX96 = sqrtPriceX96;
    this.nearestTick = nearestTick;
    this.ticks = ticks;
    if (this.ticks.length === 0) {
      this.ticks.push({ index: CL_MIN_TICK, DLiquidity: ZERO });
      this.ticks.push({ index: CL_MAX_TICK, DLiquidity: ZERO });
    }
    if (this.ticks[0].index > CL_MIN_TICK)
      this.ticks.unshift({ index: CL_MIN_TICK, DLiquidity: ZERO });
    if (this.ticks[this.ticks.length - 1].index < CL_MAX_TICK)
      this.ticks.push({ index: CL_MAX_TICK, DLiquidity: ZERO });
  }
  calcOutByIn(amountIn, direction) {
    let nextTickToCross = direction ? this.nearestTick : this.nearestTick + 1;
    const currentPriceBN = this.sqrtPriceX96;
    let currentPrice = parseInt(currentPriceBN.toString()) / two96;
    let currentLiquidityBN = this.liquidity;
    let outAmount = 0;
    let input = amountIn;
    let startFlag = true;
    while (input > 0) {
      if (nextTickToCross < 0 || nextTickToCross >= this.ticks.length)
        return { out: outAmount, gasSpent: this.swapGasCost };
      let nextTickPrice, priceDiff;
      if (startFlag) {
        const nextTickPriceBN = getSqrtRatioAtTick(this.ticks[nextTickToCross].index);
        nextTickPrice = parseInt(nextTickPriceBN.toString()) / two96;
        priceDiff = parseInt(currentPriceBN.sub(nextTickPriceBN).toString()) / two96;
        startFlag = false;
      } else {
        nextTickPrice = Math.sqrt(Math.pow(1.0001, this.ticks[nextTickToCross].index));
        priceDiff = currentPrice - nextTickPrice;
      }
      let output = 0;
      const currentLiquidity = parseInt(currentLiquidityBN.toString());
      if (direction) {
        const maxDx = currentLiquidity * priceDiff / currentPrice / nextTickPrice;
        if (input <= maxDx) {
          output = currentLiquidity * currentPrice * input / (input + currentLiquidity / currentPrice);
          input = 0;
        } else {
          output = currentLiquidity * priceDiff;
          currentPrice = nextTickPrice;
          input -= maxDx;
          if (this.ticks[nextTickToCross].index / this.tickSpacing % 2 === 0) {
            currentLiquidityBN = currentLiquidityBN.sub(this.ticks[nextTickToCross].DLiquidity);
          } else {
            currentLiquidityBN = currentLiquidityBN.add(this.ticks[nextTickToCross].DLiquidity);
          }
          nextTickToCross--;
        }
      } else {
        const maxDy = currentLiquidity * -priceDiff;
        if (input <= maxDy) {
          output = input / currentPrice / (currentPrice + input / currentLiquidity);
          input = 0;
        } else {
          output = currentLiquidity * -priceDiff / currentPrice / nextTickPrice;
          currentPrice = nextTickPrice;
          input -= maxDy;
          if (this.ticks[nextTickToCross].index / this.tickSpacing % 2 === 0) {
            currentLiquidityBN = currentLiquidityBN.add(this.ticks[nextTickToCross].DLiquidity);
          } else {
            currentLiquidityBN = currentLiquidityBN.sub(this.ticks[nextTickToCross].DLiquidity);
          }
          nextTickToCross++;
        }
      }
      outAmount += output * (1 - this.fee);
    }
    return { out: outAmount, gasSpent: this.swapGasCost };
  }
  calcInByOut(amountOut, direction) {
    let nextTickToCross = direction ? this.nearestTick : this.nearestTick + 1;
    const currentPriceBN = this.sqrtPriceX96;
    let currentPrice = parseInt(currentPriceBN.toString()) / two96;
    let currentLiquidityBN = this.liquidity;
    let input = 0;
    let outBeforeFee = amountOut / (1 - this.fee);
    let startFlag = true;
    while (outBeforeFee > 0) {
      if (nextTickToCross < 0 || nextTickToCross >= this.ticks.length)
        return { inp: input, gasSpent: this.swapGasCost };
      let nextTickPrice, priceDiff;
      if (startFlag) {
        const nextTickPriceBN = getSqrtRatioAtTick(this.ticks[nextTickToCross].index);
        nextTickPrice = parseInt(nextTickPriceBN.toString()) / two96;
        priceDiff = parseInt(currentPriceBN.sub(nextTickPriceBN).toString()) / two96;
        startFlag = false;
      } else {
        nextTickPrice = Math.sqrt(Math.pow(1.0001, this.ticks[nextTickToCross].index));
        priceDiff = currentPrice - nextTickPrice;
      }
      const currentLiquidity = parseInt(currentLiquidityBN.toString());
      if (direction) {
        const maxDy = currentLiquidity * priceDiff;
        if (outBeforeFee <= maxDy) {
          input += outBeforeFee / currentPrice / (currentPrice - outBeforeFee / currentLiquidity);
          outBeforeFee = 0;
        } else {
          input += currentLiquidity * priceDiff / currentPrice / nextTickPrice;
          currentPrice = nextTickPrice;
          outBeforeFee -= maxDy;
          if (this.ticks[nextTickToCross].index / this.tickSpacing % 2 === 0) {
            currentLiquidityBN = currentLiquidityBN.sub(this.ticks[nextTickToCross].DLiquidity);
          } else {
            currentLiquidityBN = currentLiquidityBN.add(this.ticks[nextTickToCross].DLiquidity);
          }
          nextTickToCross--;
        }
      } else {
        const maxDx = currentLiquidity * -priceDiff / currentPrice / nextTickPrice;
        if (outBeforeFee <= maxDx) {
          input += currentLiquidity * currentPrice * outBeforeFee / (currentLiquidity / currentPrice - outBeforeFee);
          outBeforeFee = 0;
        } else {
          input += currentLiquidity * -priceDiff;
          currentPrice = nextTickPrice;
          outBeforeFee -= maxDx;
          if (this.ticks[nextTickToCross].index / this.tickSpacing % 2 === 0) {
            currentLiquidityBN = currentLiquidityBN.add(this.ticks[nextTickToCross].DLiquidity);
          } else {
            currentLiquidityBN = currentLiquidityBN.sub(this.ticks[nextTickToCross].DLiquidity);
          }
          nextTickToCross++;
        }
      }
    }
    return { inp: input, gasSpent: this.swapGasCost };
  }
  calcCurrentPriceWithoutFee(direction) {
    const currentPrice = parseInt(this.sqrtPriceX96.toString()) / two96;
    const p = currentPrice * currentPrice;
    return direction ? p : 1 / p;
  }
};

// src/CurvePool.ts
import { BigNumber as BigNumber5 } from "@ethersproject/bignumber";
var CurvePool = class extends RPool {
  A;
  D;
  rate0BN;
  rate1BN18;
  rate0;
  rate1;
  reserve0Rated;
  reserve1Rated;
  constructor(address, token0, token1, fee, A, reserve0, reserve1, ratio = 1) {
    super(address, token0, token1, fee, reserve0, reserve1, void 0, 9e4);
    this.A = A;
    this.D = BigNumber5.from(0);
    const decimalsMin = Math.min(this.token0.decimals, this.token1.decimals);
    this.rate0 = Math.pow(10, this.token1.decimals - decimalsMin);
    this.rate1 = Math.pow(10, this.token0.decimals - decimalsMin) * ratio;
    this.rate0BN = getBigNumber(this.rate0);
    this.rate1BN18 = getBigNumber(this.rate1 * 1e18);
    this.reserve0Rated = this.reserve0.mul(this.rate0BN);
    this.reserve1Rated = this.reserve1.mul(this.rate1BN18).div(getBigNumber(1e18));
  }
  updateReserves(res0, res1) {
    this.D = BigNumber5.from(0);
    this.reserve0 = res0;
    this.reserve1 = res1;
    this.reserve0Rated = this.reserve0.mul(this.rate0BN);
    this.reserve1Rated = this.reserve1.mul(this.rate1BN18).div(getBigNumber(1e18));
  }
  computeLiquidity() {
    if (!this.D.isZero())
      return this.D;
    const r0 = this.reserve0Rated;
    const r1 = this.reserve1Rated;
    if (r0.isZero() && r1.isZero())
      return BigNumber5.from(0);
    const s = r0.add(r1);
    const nA = BigNumber5.from(this.A * 2);
    let prevD;
    let D = s;
    for (let i = 0; i < 256; i++) {
      const dP = D.mul(D).div(r0).mul(D).div(r1).div(4);
      prevD = D;
      D = nA.mul(s).add(dP.mul(2)).mul(D).div(nA.sub(1).mul(D).add(dP.mul(3)));
      if (D.sub(prevD).abs().lte(1)) {
        break;
      }
    }
    this.D = D;
    return D;
  }
  computeY(x) {
    const D = this.computeLiquidity();
    const nA = this.A * 2;
    const c = D.mul(D).div(x.mul(2)).mul(D).div(nA * 2);
    const b = D.div(nA).add(x);
    let yPrev;
    let y = D;
    for (let i = 0; i < 256; i++) {
      yPrev = y;
      y = y.mul(y).add(c).div(y.mul(2).add(b).sub(D));
      if (y.sub(yPrev).abs().lte(1)) {
        break;
      }
    }
    return y;
  }
  calcOutByIn(amountIn, direction) {
    amountIn *= direction ? this.rate0 : this.rate1;
    const xBN = direction ? this.reserve0Rated : this.reserve1Rated;
    const yBN = direction ? this.reserve1Rated : this.reserve0Rated;
    const xNewBN = xBN.add(getBigNumber(amountIn));
    const yNewBN = this.computeY(xNewBN);
    const dy = parseInt(yBN.sub(yNewBN).toString()) / (direction ? this.rate1 : this.rate0);
    if (parseInt(yNewBN.toString()) < this.minLiquidity)
      throw "Curve pool OutOfLiquidity";
    return { out: dy * (1 - this.fee), gasSpent: this.swapGasCost };
  }
  calcInByOut(amountOut, direction) {
    amountOut *= direction ? this.rate1 : this.rate0;
    const xBN = direction ? this.reserve0Rated : this.reserve1Rated;
    const yBN = direction ? this.reserve1Rated : this.reserve0Rated;
    let yNewBN = yBN.sub(getBigNumber(amountOut / (1 - this.fee)));
    if (yNewBN.lt(1))
      yNewBN = BigNumber5.from(1);
    const xNewBN = this.computeY(yNewBN);
    const input = Math.round(
      parseInt(xNewBN.sub(xBN).toString()) / (direction ? this.rate0 : this.rate1)
    );
    return { inp: input, gasSpent: this.swapGasCost };
  }
  calcCurrentPriceWithoutFee(direction) {
    return this.calcPrice(0, direction, false);
  }
  calcPrice(amountIn, direction, takeFeeIntoAccount) {
    const xBN = direction ? this.reserve0Rated : this.reserve1Rated;
    const x = parseInt(xBN.toString());
    const oneMinusFee = takeFeeIntoAccount ? 1 - this.fee : 1;
    const D = parseInt(this.computeLiquidity().toString());
    const A = this.A / 2;
    const xI = x + amountIn;
    const b = 4 * A * xI + D - 4 * A * D;
    const ac4 = D * D * D / xI;
    const Ds = Math.sqrt(b * b + 4 * A * ac4);
    const price = (0.5 - (2 * b - ac4 / xI) / Ds / 4) * oneMinusFee;
    const scale = this.rate0 / this.rate1;
    return direction ? price * scale : price / scale;
  }
};

// src/deprecated/MultiRouterMath.ts
import { BigNumber as BigNumber7 } from "@ethersproject/bignumber";

// src/deprecated/MultiRouterTypes.ts
import { BigNumber as BigNumber6 } from "@ethersproject/bignumber";
var PoolType = /* @__PURE__ */ ((PoolType2) => {
  PoolType2["ConstantProduct"] = "ConstantProduct";
  PoolType2["Weighted"] = "Weighted";
  PoolType2["Hybrid"] = "Hybrid";
  PoolType2["ConcentratedLiquidity"] = "ConcentratedLiquidity";
  return PoolType2;
})(PoolType || {});
var Pool = class {
  address;
  token0;
  token1;
  type;
  reserve0;
  reserve1;
  fee;
  minLiquidity;
  swapGasCost;
  constructor(_info) {
    const info = {
      minLiquidity: 1e3,
      swapGasCost: 4e4,
      ..._info
    };
    this.address = info.address;
    this.token0 = info.token0;
    this.token1 = info.token1;
    this.type = info.type;
    this.reserve0 = info.reserve0;
    this.reserve1 = info.reserve1;
    this.fee = info.fee;
    this.minLiquidity = info.minLiquidity;
    this.swapGasCost = info.swapGasCost;
  }
};
var RConstantProductPool = class extends Pool {
  constructor(info) {
    super({
      type: "ConstantProduct" /* ConstantProduct */,
      ...info
    });
  }
};
var RHybridPool = class extends Pool {
  A;
  constructor(info) {
    super({
      type: "Hybrid" /* Hybrid */,
      ...info
    });
    this.A = info.A;
  }
};
var RWeightedPool = class extends Pool {
  weight0;
  weight1;
  constructor(info) {
    super({
      type: "Weighted" /* Weighted */,
      ...info
    });
    this.weight0 = info.weight0;
    this.weight1 = info.weight1;
  }
};
var RConcentratedLiquidityPool = class extends Pool {
  liquidity;
  sqrtPrice;
  nearestTick;
  ticks;
  constructor(info) {
    super({
      type: "ConcentratedLiquidity" /* ConcentratedLiquidity */,
      reserve0: BigNumber6.from(0),
      reserve1: BigNumber6.from(0),
      ...info
    });
    this.liquidity = info.liquidity;
    this.sqrtPrice = info.sqrtPrice;
    this.nearestTick = info.nearestTick;
    this.ticks = info.ticks;
  }
};

// src/deprecated/MultiRouterMath.ts
var A_PRECISION = 100;
var DCacheBN = /* @__PURE__ */ new Map();
function HybridComputeLiquidity(pool) {
  const res = DCacheBN.get(pool);
  if (res !== void 0)
    return res;
  const r0 = pool.reserve0;
  const r1 = pool.reserve1;
  if (r0.isZero() && r1.isZero()) {
    DCacheBN.set(pool, BigNumber7.from(0));
    return BigNumber7.from(0);
  }
  const s = r0.add(r1);
  const nA = BigNumber7.from(pool.A * 2);
  let prevD;
  let D = s;
  for (let i = 0; i < 256; i++) {
    const dP = D.mul(D).div(r0).mul(D).div(r1).div(4);
    prevD = D;
    D = nA.mul(s).div(A_PRECISION).add(dP.mul(2)).mul(D).div(nA.div(A_PRECISION).sub(1).mul(D).add(dP.mul(3)));
    if (D.sub(prevD).abs().lte(1)) {
      break;
    }
  }
  DCacheBN.set(pool, D);
  return D;
}
function HybridgetY(pool, x) {
  const D = HybridComputeLiquidity(pool);
  const nA = pool.A * 2;
  const c = D.mul(D).div(x.mul(2)).mul(D).div(nA * 2 / A_PRECISION);
  const b = D.mul(A_PRECISION).div(nA).add(x);
  let yPrev;
  let y = D;
  for (let i = 0; i < 256; i++) {
    yPrev = y;
    y = y.mul(y).add(c).div(y.mul(2).add(b).sub(D));
    if (y.sub(yPrev).abs().lte(1)) {
      break;
    }
  }
  return y;
}
function calcOutByIn(pool, amountIn, direction = true) {
  const xBN = direction ? pool.reserve0 : pool.reserve1;
  const yBN = direction ? pool.reserve1 : pool.reserve0;
  switch (pool.type) {
    case "ConstantProduct" /* ConstantProduct */: {
      const x = parseInt(xBN.toString());
      const y = parseInt(yBN.toString());
      return y * amountIn / (x / (1 - pool.fee) + amountIn);
    }
    case "Weighted" /* Weighted */: {
      const x = parseInt(xBN.toString());
      const y = parseInt(yBN.toString());
      const wPool = pool;
      const weightRatio = direction ? wPool.weight0 / wPool.weight1 : wPool.weight1 / wPool.weight0;
      const actualIn = amountIn * (1 - pool.fee);
      const out = y * (1 - Math.pow(x / (x + actualIn), weightRatio));
      return out;
    }
    case "Hybrid" /* Hybrid */: {
      const xNewBN = xBN.add(getBigNumber(amountIn * (1 - pool.fee)));
      const yNewBN = HybridgetY(pool, xNewBN);
      const dy = parseInt(yBN.sub(yNewBN).toString());
      return dy;
    }
  }
  return -1;
}
var OutOfLiquidity = class extends Error {
};
function calcInByOut(pool, amountOut, direction) {
  let input = 0;
  const xBN = direction ? pool.reserve0 : pool.reserve1;
  const yBN = direction ? pool.reserve1 : pool.reserve0;
  switch (pool.type) {
    case "ConstantProduct" /* ConstantProduct */: {
      const x = parseInt(xBN.toString());
      const y = parseInt(yBN.toString());
      input = x * amountOut / (1 - pool.fee) / (y - amountOut);
      break;
    }
    case "Weighted" /* Weighted */: {
      const x = parseInt(xBN.toString());
      const y = parseInt(yBN.toString());
      const wPool = pool;
      const weightRatio = direction ? wPool.weight0 / wPool.weight1 : wPool.weight1 / wPool.weight0;
      input = x * (1 - pool.fee) * (Math.pow(1 - amountOut / y, -weightRatio) - 1);
      break;
    }
    case "Hybrid" /* Hybrid */: {
      let yNewBN = yBN.sub(getBigNumber(amountOut));
      if (yNewBN.lt(1))
        yNewBN = BigNumber7.from(1);
      const xNewBN = HybridgetY(pool, yNewBN);
      input = Math.round(parseInt(xNewBN.sub(xBN).toString()) / (1 - pool.fee));
      break;
    }
    default:
      console.error("Unknown pool type");
  }
  if (input < 1)
    input = 1;
  return input;
}
function calcPrice(pool, amountIn, takeFeeIntoAccount = true) {
  const r0 = parseInt(pool.reserve0.toString());
  const r1 = parseInt(pool.reserve1.toString());
  const oneMinusFee = takeFeeIntoAccount ? 1 - pool.fee : 1;
  switch (pool.type) {
    case "ConstantProduct" /* ConstantProduct */: {
      const x = r0 / oneMinusFee;
      return r1 * x / (x + amountIn) / (x + amountIn);
    }
    case "Weighted" /* Weighted */: {
      const wPool = pool;
      const weightRatio = wPool.weight0 / wPool.weight1;
      const x = r0 + amountIn * oneMinusFee;
      return r1 * weightRatio * oneMinusFee * Math.pow(r0 / x, weightRatio) / x;
    }
    case "Hybrid" /* Hybrid */: {
      const hPool = pool;
      const D = parseInt(HybridComputeLiquidity(hPool).toString());
      const A = hPool.A / A_PRECISION;
      const x = r0 + amountIn;
      const b = 4 * A * x + D - 4 * A * D;
      const ac4 = D * D * D / x;
      const Ds = Math.sqrt(b * b + 4 * A * ac4);
      const res = (0.5 - (2 * b - ac4 / x) / Ds / 4) * oneMinusFee;
      return res;
    }
  }
  return 0;
}
function calcInputByPriceConstantMean(pool, price) {
  const r0 = parseInt(pool.reserve0.toString());
  const r1 = parseInt(pool.reserve1.toString());
  const weightRatio = pool.weight0 / pool.weight1;
  const t = r1 * price * weightRatio * (1 - pool.fee) * Math.pow(r0, weightRatio);
  return (Math.pow(t, 1 / (weightRatio + 1)) - r0) / (1 - pool.fee);
}
function calcInputByPrice(pool, priceEffective, hint = 1) {
  switch (pool.type) {
    case "ConstantProduct" /* ConstantProduct */: {
      const r0 = parseInt(pool.reserve0.toString());
      const r1 = parseInt(pool.reserve1.toString());
      const x = r0 / (1 - pool.fee);
      const res = Math.sqrt(r1 * x * priceEffective) - x;
      return res;
    }
    case "Weighted" /* Weighted */: {
      const res = calcInputByPriceConstantMean(pool, priceEffective);
      return res;
    }
    case "Hybrid" /* Hybrid */: {
      return revertPositive((x) => 1 / calcPrice(pool, x), priceEffective, hint);
    }
  }
  return 0;
}

// src/Graph.ts
import { BigNumber as BigNumber9 } from "@ethersproject/bignumber";

// src/StableSwapPool.ts
import { BigNumber as BigNumber8 } from "@ethersproject/bignumber";
function toAmountBN(share, total) {
  if (total.base.isZero() || total.elastic.isZero())
    return share;
  return share.mul(total.elastic).div(total.base);
}
function toShareBN(elastic, total) {
  if (total.base.isZero() || total.elastic.isZero())
    return elastic;
  return elastic.mul(total.base).div(total.elastic);
}
var RebaseInternal = class {
  elastic2Base;
  rebaseBN;
  constructor(rebase) {
    this.rebaseBN = rebase;
    if (rebase.base.isZero() || rebase.elastic.isZero())
      this.elastic2Base = 1;
    else
      this.elastic2Base = parseInt(rebase.elastic.toString()) / parseInt(rebase.base.toString());
  }
  toAmount(share) {
    return share * this.elastic2Base;
  }
  toShare(amount) {
    return amount / this.elastic2Base;
  }
  toAmountBN(share) {
    return toAmountBN(share, this.rebaseBN);
  }
};
function realReservesToAdjusted(reserve, total, decimals) {
  const amount = toAmountBN(reserve, total);
  return amount.mul(1e12).div(getBigNumber(Math.pow(10, decimals)));
}
function adjustedReservesToReal(reserve, total, decimals) {
  const amount = reserve.mul(getBigNumber(Math.pow(10, decimals))).div(1e12);
  return toShareBN(amount, total);
}
var StableSwapRPool = class extends RPool {
  k;
  decimals0;
  decimals1;
  decimalsCompensation0;
  decimalsCompensation1;
  total0;
  total1;
  constructor(address, token0, token1, fee, reserve0, reserve1, decimals0, decimals1, total0, total1) {
    super(
      address,
      token0,
      token1,
      fee,
      realReservesToAdjusted(reserve0, total0, decimals0),
      realReservesToAdjusted(reserve1, total1, decimals1)
    );
    this.k = BigNumber8.from(0);
    this.decimals0 = decimals0;
    this.decimals1 = decimals1;
    this.decimalsCompensation0 = Math.pow(10, 12 - decimals0);
    this.decimalsCompensation1 = Math.pow(10, 12 - decimals1);
    this.total0 = new RebaseInternal(total0);
    this.total1 = new RebaseInternal(total1);
  }
  getReserve0() {
    return adjustedReservesToReal(this.reserve0, this.total0.rebaseBN, this.decimals0);
  }
  getReserve1() {
    return adjustedReservesToReal(this.reserve1, this.total1.rebaseBN, this.decimals1);
  }
  granularity0() {
    return Math.max(1 / this.decimalsCompensation0, 1);
  }
  granularity1() {
    return Math.max(1 / this.decimalsCompensation1, 1);
  }
  updateReserves(res0, res1) {
    this.k = BigNumber8.from(0);
    this.reserve0 = realReservesToAdjusted(res0, this.total0.rebaseBN, this.decimals0);
    this.reserve1 = realReservesToAdjusted(res1, this.total1.rebaseBN, this.decimals1);
  }
  updateReservesAmounts(res0, res1) {
    this.k = BigNumber8.from(0);
    this.reserve0 = res0.mul(1e12).div(getBigNumber(Math.pow(10, this.decimals0)));
    this.reserve1 = res1.mul(1e12).div(getBigNumber(Math.pow(10, this.decimals1)));
  }
  getTotal0() {
    return this.total0.rebaseBN;
  }
  getTotal1() {
    return this.total1.rebaseBN;
  }
  updateTotals(total0, total1) {
    this.total0 = new RebaseInternal(total0);
    this.total1 = new RebaseInternal(total1);
  }
  updateTotal0(total0) {
    this.total0 = new RebaseInternal(total0);
  }
  updateTotal1(total1) {
    this.total1 = new RebaseInternal(total1);
  }
  computeK() {
    if (this.k.isZero()) {
      const x = this.reserve0;
      const y = this.reserve1;
      this.k = x.mul(y).mul(x.mul(x).add(y.mul(y)));
    }
    return this.k;
  }
  computeY(x, yHint) {
    const k = this.computeK();
    const x2 = x.shl(1);
    const x3 = x.mul(3);
    const xCube = x.mul(x).mul(x);
    let yPrev = yHint, y = yHint;
    for (let i = 0; i < 255; ++i) {
      const ySquare = y.mul(y);
      const yCube = ySquare.mul(y);
      y = yCube.mul(x2).add(k).div(ySquare.mul(x3).add(xCube));
      if (y.sub(yPrev).abs().lte(1))
        break;
      yPrev = y;
    }
    return y;
  }
  calcOutByIn(amountIn, direction) {
    amountIn = direction ? this.total0.toAmount(amountIn) : this.total1.toAmount(amountIn);
    amountIn *= direction ? this.decimalsCompensation0 : this.decimalsCompensation1;
    const x = direction ? this.reserve0 : this.reserve1;
    const y = direction ? this.reserve1 : this.reserve0;
    const xNew = x.add(getBigNumber(Math.floor(amountIn * (1 - this.fee))));
    const yNew = this.computeY(xNew, y);
    const outA = parseInt(y.sub(yNew).toString()) - 1;
    const outB = Math.max(outA, 0);
    const outC = direction ? this.total1.toShare(outB) : this.total0.toShare(outB);
    const out = outC / (direction ? this.decimalsCompensation1 : this.decimalsCompensation0);
    const initialReserve = direction ? this.getReserve1() : this.getReserve0();
    if (initialReserve.sub(getBigNumber(out)).lt(this.minLiquidity))
      throw new Error("StableSwap OutOfLiquidity");
    return { out, gasSpent: this.swapGasCost };
  }
  calcInByOut(amountOut, direction) {
    amountOut = direction ? this.total1.toAmount(amountOut) : this.total0.toAmount(amountOut);
    amountOut *= direction ? this.decimalsCompensation1 : this.decimalsCompensation0;
    const x = direction ? this.reserve0 : this.reserve1;
    const y = direction ? this.reserve1 : this.reserve0;
    const yNew = y.sub(getBigNumber(Math.ceil(amountOut)));
    if (yNew.lt(this.minLiquidity)) {
      return { inp: Number.POSITIVE_INFINITY, gasSpent: this.swapGasCost };
    }
    const xNew = this.computeY(yNew, x);
    const inp0 = parseInt(xNew.sub(x).toString()) / (1 - this.fee);
    const inp1 = direction ? this.total0.toShare(inp0) : this.total1.toShare(inp0);
    const inp2 = inp1 / (direction ? this.decimalsCompensation0 : this.decimalsCompensation1);
    const inp = Math.max(inp2, 1);
    return { inp, gasSpent: this.swapGasCost };
  }
  calcCurrentPriceWithoutFee(direction) {
    const calcDirection = this.reserve0.gt(this.reserve1);
    const xBN = calcDirection ? this.reserve0 : this.reserve1;
    const x = parseInt(xBN.toString());
    const k = parseInt(this.computeK().toString());
    const q = k / x / 2;
    const qD = -q / x;
    const Q = Math.pow(x, 6) / 27 + q * q;
    const QD = 6 * Math.pow(x, 5) / 27 + 2 * q * qD;
    const sqrtQ = Math.sqrt(Q);
    const sqrtQD = 1 / 2 / sqrtQ * QD;
    const a = sqrtQ + q;
    const aD = sqrtQD + qD;
    const b = sqrtQ - q;
    const bD = sqrtQD - qD;
    const a3 = Math.pow(a, 1 / 3);
    const a3D = 1 / 3 * a3 / a * aD;
    const b3 = Math.pow(b, 1 / 3);
    const b3D = 1 / 3 * b3 / b * bD;
    const yD = a3D - b3D;
    const yDShares = calcDirection ? this.total1.toShare(this.total0.toAmount(yD)) : this.total0.toShare(this.total1.toAmount(yD));
    const price = calcDirection == direction ? -yDShares : -1 / yDShares;
    const scale = this.decimalsCompensation0 / this.decimalsCompensation1;
    return direction ? price * scale : price / scale;
  }
};

// src/Graph.ts
var RouteStatus = /* @__PURE__ */ ((RouteStatus2) => {
  RouteStatus2["Success"] = "Success";
  RouteStatus2["NoWay"] = "NoWay";
  RouteStatus2["Partial"] = "Partial";
  return RouteStatus2;
})(RouteStatus || {});
var Edge = class {
  pool;
  vert0;
  vert1;
  canBeUsed;
  direction;
  amountInPrevious;
  amountOutPrevious;
  spentGas;
  spentGasNew;
  bestEdgeIncome;
  constructor(p, v0, v1) {
    this.pool = p;
    this.vert0 = v0;
    this.vert1 = v1;
    this.amountInPrevious = 0;
    this.amountOutPrevious = 0;
    this.canBeUsed = true;
    this.direction = true;
    this.spentGas = 0;
    this.spentGasNew = 0;
    this.bestEdgeIncome = 0;
  }
  cleanTmpData() {
    this.amountInPrevious = 0;
    this.amountOutPrevious = 0;
    this.canBeUsed = true;
    this.direction = true;
    this.spentGas = 0;
    this.spentGasNew = 0;
    this.bestEdgeIncome = 0;
  }
  reserve(v) {
    return v === this.vert0 ? this.pool.getReserve0() : this.pool.getReserve1();
  }
  calcOutput(v, amountIn) {
    let res, gas;
    if (v === this.vert1) {
      if (this.direction) {
        if (amountIn < this.amountOutPrevious) {
          const { inp, gasSpent } = this.pool.calcInByOut(this.amountOutPrevious - amountIn, true);
          res = this.amountInPrevious - inp;
          gas = gasSpent;
        } else {
          const { out, gasSpent } = this.pool.calcOutByIn(amountIn - this.amountOutPrevious, false);
          res = out + this.amountInPrevious;
          gas = gasSpent;
        }
      } else {
        const { out, gasSpent } = this.pool.calcOutByIn(this.amountOutPrevious + amountIn, false);
        res = out - this.amountInPrevious;
        gas = gasSpent;
      }
    } else {
      if (this.direction) {
        const { out, gasSpent } = this.pool.calcOutByIn(this.amountInPrevious + amountIn, true);
        res = out - this.amountOutPrevious;
        gas = gasSpent;
      } else {
        if (amountIn < this.amountInPrevious) {
          const { inp, gasSpent } = this.pool.calcInByOut(this.amountInPrevious - amountIn, false);
          res = this.amountOutPrevious - inp;
          gas = gasSpent;
        } else {
          const { out, gasSpent } = this.pool.calcOutByIn(amountIn - this.amountInPrevious, true);
          res = out + this.amountOutPrevious;
          gas = gasSpent;
        }
      }
    }
    return { out: res, gasSpent: gas - this.spentGas };
  }
  calcInput(v, amountOut) {
    let res, gas;
    if (v === this.vert1) {
      if (!this.direction) {
        if (amountOut < this.amountOutPrevious) {
          const { out, gasSpent } = this.pool.calcOutByIn(this.amountOutPrevious - amountOut, false);
          res = this.amountInPrevious - out;
          gas = gasSpent;
        } else {
          const { inp, gasSpent } = this.pool.calcInByOut(amountOut - this.amountOutPrevious, true);
          res = inp + this.amountInPrevious;
          gas = gasSpent;
        }
      } else {
        const { inp, gasSpent } = this.pool.calcInByOut(this.amountOutPrevious + amountOut, true);
        res = inp - this.amountInPrevious;
        gas = gasSpent;
      }
    } else {
      if (!this.direction) {
        const { inp, gasSpent } = this.pool.calcInByOut(this.amountInPrevious + amountOut, false);
        res = inp - this.amountOutPrevious;
        gas = gasSpent;
      } else {
        if (amountOut < this.amountInPrevious) {
          const { out, gasSpent } = this.pool.calcOutByIn(this.amountInPrevious - amountOut, true);
          res = this.amountOutPrevious - out;
          gas = gasSpent;
        } else {
          const { inp, gasSpent } = this.pool.calcInByOut(amountOut - this.amountInPrevious, false);
          res = inp + this.amountOutPrevious;
          gas = gasSpent;
        }
      }
    }
    return { inp: res, gasSpent: gas - this.spentGas };
  }
  checkMinimalLiquidityExceededAfterSwap(from, amountOut) {
    if (from === this.vert0) {
      const r1 = parseInt(this.pool.getReserve1().toString());
      if (this.direction) {
        return r1 - amountOut - this.amountOutPrevious < this.pool.minLiquidity;
      } else {
        return r1 - amountOut + this.amountOutPrevious < this.pool.minLiquidity;
      }
    } else {
      const r0 = parseInt(this.pool.getReserve0().toString());
      if (this.direction) {
        return r0 - amountOut + this.amountInPrevious < this.pool.minLiquidity;
      } else {
        return r0 - amountOut - this.amountInPrevious < this.pool.minLiquidity;
      }
    }
  }
  testApply(from, amountIn, amountOut) {
    console.assert(this.amountInPrevious * this.amountOutPrevious >= 0);
    const inPrev = this.direction ? this.amountInPrevious : -this.amountInPrevious;
    const outPrev = this.direction ? this.amountOutPrevious : -this.amountOutPrevious;
    const to = from.getNeibour(this);
    let directionNew, amountInNew = 0, amountOutNew = 0;
    if (to) {
      const inInc = from === this.vert0 ? amountIn : -amountOut;
      const outInc = from === this.vert0 ? amountOut : -amountIn;
      const inNew = inPrev + inInc;
      const outNew = outPrev + outInc;
      console.assert(inNew * outNew >= 0);
      if (inNew >= 0) {
        directionNew = true;
        amountInNew = inNew;
        amountOutNew = outNew;
      } else {
        directionNew = false;
        amountInNew = -inNew;
        amountOutNew = -outNew;
      }
    } else
      console.error("Error 221");
    if (directionNew) {
      const calc = this.pool.calcOutByIn(amountInNew, true).out;
      const res = closeValues(amountOutNew, calc, 1e-6);
      if (!res)
        console.log("Err 225-1 !!", amountOutNew, calc, Math.abs(calc / amountOutNew - 1));
      return res;
    } else {
      const calc = this.pool.calcOutByIn(amountOutNew, false).out;
      const res = closeValues(amountInNew, calc, 1e-6);
      if (!res)
        console.log("Err 225-2!!", amountInNew, calc, Math.abs(calc / amountInNew - 1));
      return res;
    }
  }
  applySwap(from) {
    console.assert(this.amountInPrevious * this.amountOutPrevious >= 0);
    const inPrev = this.direction ? this.amountInPrevious : -this.amountInPrevious;
    const outPrev = this.direction ? this.amountOutPrevious : -this.amountOutPrevious;
    const to = from.getNeibour(this);
    if (to) {
      const inInc = from === this.vert0 ? from.bestIncome : -to.bestIncome;
      const outInc = from === this.vert0 ? to.bestIncome : -from.bestIncome;
      const inNew = inPrev + inInc;
      const outNew = outPrev + outInc;
      console.assert(inNew * outNew >= 0);
      if (inNew >= 0) {
        this.direction = true;
        this.amountInPrevious = inNew;
        this.amountOutPrevious = outNew;
      } else {
        this.direction = false;
        this.amountInPrevious = -inNew;
        this.amountOutPrevious = -outNew;
      }
    } else
      console.error("Error 221");
    this.spentGas = this.spentGasNew;
    ASSERT(() => {
      let precision = 1e-9;
      if (this.pool instanceof StableSwapRPool) {
        let price = this.pool.calcCurrentPriceWithoutFee(true);
        if (price < 1)
          price = 1 / price;
        if (price > 1e8) {
          precision = 2e-3;
        }
      }
      if (this.direction) {
        const granularity = this.pool.granularity1();
        return closeValues(
          this.amountOutPrevious / granularity,
          this.pool.calcOutByIn(this.amountInPrevious, this.direction).out / granularity,
          precision
        );
      } else {
        const granularity = this.pool.granularity0();
        return closeValues(
          this.amountInPrevious / granularity,
          this.pool.calcOutByIn(this.amountOutPrevious, this.direction).out / granularity,
          precision
        );
      }
    }, `Error 225`);
  }
};
var Vertice = class {
  token;
  edges;
  price;
  gasPrice;
  bestIncome;
  gasSpent;
  bestTotal;
  bestSource;
  checkLine;
  constructor(t) {
    this.token = t;
    setTokenId(this.token);
    this.edges = [];
    this.price = 0;
    this.gasPrice = 0;
    this.bestIncome = 0;
    this.gasSpent = 0;
    this.bestTotal = 0;
    this.bestSource = void 0;
    this.checkLine = -1;
  }
  cleanTmpData() {
    this.bestIncome = 0;
    this.gasSpent = 0;
    this.bestTotal = 0;
    this.bestSource = void 0;
    this.checkLine = -1;
  }
  getNeibour(e) {
    if (!e)
      return;
    return e.vert0 === this ? e.vert1 : e.vert0;
  }
  getOutputEdges() {
    return this.edges.filter((e) => {
      if (!e.canBeUsed)
        return false;
      if (e.amountInPrevious === 0)
        return false;
      if (e.direction !== (e.vert0 === this))
        return false;
      return true;
    });
  }
  getInputEdges() {
    return this.edges.filter((e) => {
      if (!e.canBeUsed)
        return false;
      if (e.amountInPrevious === 0)
        return false;
      if (e.direction === (e.vert0 === this))
        return false;
      return true;
    });
  }
};
var Graph = class {
  vertices;
  edges;
  tokens;
  constructor(pools, start, baseTokenOrNetworks, gasPriceSingleNetwork, minPriceLiquidity = 0) {
    const networks = baseTokenOrNetworks instanceof Array ? baseTokenOrNetworks : [
      {
        chainId: baseTokenOrNetworks.chainId,
        baseToken: baseTokenOrNetworks,
        gasPrice: gasPriceSingleNetwork || 0
      }
    ];
    setTokenId(...networks.map((n) => n.baseToken));
    this.vertices = [];
    this.edges = [];
    this.tokens = /* @__PURE__ */ new Map();
    pools.forEach((p) => {
      const v0 = this.getOrCreateVertice(p.token0);
      const v1 = this.getOrCreateVertice(p.token1);
      const edge = new Edge(p, v0, v1);
      v0.edges.push(edge);
      v1.edges.push(edge);
      this.edges.push(edge);
    });
    const startV = this.getVert(start);
    if (startV !== void 0)
      this.setPricesStable(startV, 1, networks, minPriceLiquidity);
  }
  getVert(t) {
    return this.tokens.get(t.tokenId);
  }
  cleanTmpData() {
    this.edges.forEach((e) => e.cleanTmpData());
    this.vertices.forEach((v) => v.cleanTmpData());
  }
  setPricesStable(from, price, networks, minLiquidity = 0) {
    const processedVert = /* @__PURE__ */ new Set();
    let nextEdges = [];
    const edgeValues = /* @__PURE__ */ new Map();
    const value = (e) => edgeValues.get(e);
    function addVertice(v, price2) {
      v.price = price2;
      const newEdges = v.edges.filter((e) => {
        if (processedVert.has(v.getNeibour(e)))
          return false;
        if (e.pool.alwaysAppropriateForPricing())
          return true;
        const liquidity = price2 * parseInt(e.reserve(v).toString());
        if (liquidity < minLiquidity)
          return false;
        edgeValues.set(e, liquidity);
        return true;
      });
      newEdges.sort((e1, e2) => value(e1) - value(e2));
      const res = [];
      while (nextEdges.length && newEdges.length) {
        if (value(nextEdges[0]) < value(newEdges[0]))
          res.push(nextEdges.shift());
        else
          res.push(newEdges.shift());
      }
      nextEdges = [...res, ...nextEdges, ...newEdges];
      processedVert.add(v);
    }
    addVertice(from, price);
    while (nextEdges.length > 0) {
      const bestEdge = nextEdges.pop();
      const [vFrom, vTo] = processedVert.has(bestEdge.vert1) ? [bestEdge.vert1, bestEdge.vert0] : [bestEdge.vert0, bestEdge.vert1];
      if (processedVert.has(vTo))
        continue;
      const p = bestEdge.pool.calcCurrentPriceWithoutFee(vFrom === bestEdge.vert1);
      addVertice(vTo, vFrom.price * p);
    }
    const gasPrice = /* @__PURE__ */ new Map();
    networks.forEach((n) => {
      const vPrice = this.getVert(n.baseToken)?.price || 0;
      gasPrice.set(n.chainId, n.gasPrice * vPrice);
    });
    processedVert.forEach((v) => {
      const gasPriceChainId = gasPrice.get(v.token.chainId);
      console.assert(gasPriceChainId !== void 0, "Error 427");
      console.assert(v.price !== 0, "Error 428");
      v.gasPrice = gasPriceChainId / v.price;
    });
  }
  setPricesStableInsideChain(from, price, gasPrice) {
    const processedVert = /* @__PURE__ */ new Set();
    let nextEdges = [];
    const edgeValues = /* @__PURE__ */ new Map();
    const value = (e) => edgeValues.get(e);
    function addVertice(v, price2, gasPrice2) {
      v.price = price2;
      v.gasPrice = gasPrice2;
      const newEdges = v.edges.filter((e) => {
        const newV = v.getNeibour(e);
        return newV?.token.chainId === v.token.chainId && !processedVert.has(v.getNeibour(e));
      });
      newEdges.forEach((e) => edgeValues.set(e, price2 * parseInt(e.reserve(v).toString())));
      newEdges.sort((e1, e2) => value(e1) - value(e2));
      const res = [];
      while (nextEdges.length && newEdges.length) {
        if (value(nextEdges[0]) < value(newEdges[0]))
          res.push(nextEdges.shift());
        else
          res.push(newEdges.shift());
      }
      nextEdges = [...res, ...nextEdges, ...newEdges];
      processedVert.add(v);
    }
    addVertice(from, price, gasPrice);
    while (nextEdges.length > 0) {
      const bestEdge = nextEdges.pop();
      const [vFrom, vTo] = processedVert.has(bestEdge.vert1) ? [bestEdge.vert1, bestEdge.vert0] : [bestEdge.vert0, bestEdge.vert1];
      if (processedVert.has(vTo))
        continue;
      const p = bestEdge.pool.calcCurrentPriceWithoutFee(vFrom === bestEdge.vert1);
      addVertice(vTo, vFrom.price * p, vFrom.gasPrice / p);
    }
  }
  setPrices(from, price, gasPrice) {
    if (from.price !== 0)
      return;
    from.price = price;
    from.gasPrice = gasPrice;
    const edges = from.edges.map((e) => [e, parseInt(e.reserve(from).toString())]).sort(([_1, r1], [_2, r2]) => r2 - r1);
    edges.forEach(([e, _]) => {
      const v = e.vert0 === from ? e.vert1 : e.vert0;
      if (v.price !== 0)
        return;
      const p = e.pool.calcCurrentPriceWithoutFee(from === e.vert1);
      this.setPrices(v, price * p, gasPrice / p);
    });
  }
  getOrCreateVertice(token) {
    let vert = this.getVert(token);
    if (vert)
      return vert;
    vert = new Vertice(token);
    this.vertices.push(vert);
    this.tokens.set(token.tokenId, vert);
    return vert;
  }
  findBestPathExactIn(from, to, amountIn) {
    const start = this.getVert(from);
    const finish = this.getVert(to);
    if (!start || !finish)
      return;
    this.edges.forEach((e) => {
      e.bestEdgeIncome = 0;
      e.spentGasNew = 0;
    });
    this.vertices.forEach((v) => {
      v.bestIncome = 0;
      v.gasSpent = 0;
      v.bestTotal = 0;
      v.bestSource = void 0;
      v.checkLine = -1;
    });
    start.bestIncome = amountIn;
    start.bestTotal = amountIn;
    const processedVert = /* @__PURE__ */ new Set();
    const nextVertList = [start];
    let debug_info = ``;
    let checkLine = 0;
    for (; ; ) {
      let closestVert;
      let closestTotal;
      let closestPosition = 0;
      nextVertList.forEach((v, i) => {
        if (closestTotal === void 0 || v.bestTotal > closestTotal) {
          closestTotal = v.bestTotal;
          closestVert = v;
          closestPosition = i;
        }
      });
      if (!closestVert)
        return;
      closestVert.checkLine = checkLine++;
      if (closestVert === finish) {
        const bestPath = [];
        for (let v = finish; v?.bestSource; v = v.getNeibour(v.bestSource)) {
          bestPath.unshift(v.bestSource);
        }
        DEBUG(() => console.log(debug_info));
        return {
          path: bestPath,
          output: finish.bestIncome,
          gasSpent: finish.gasSpent,
          totalOutput: finish.bestTotal
        };
      }
      nextVertList.splice(closestPosition, 1);
      closestVert.edges.forEach((e) => {
        const v2 = closestVert === e.vert0 ? e.vert1 : e.vert0;
        if (processedVert.has(v2))
          return;
        let newIncome, gas;
        try {
          const { out, gasSpent } = e.calcOutput(closestVert, closestVert.bestIncome);
          if (!isFinite(out) || !isFinite(gasSpent))
            return;
          newIncome = out;
          gas = gasSpent;
        } catch (err) {
          e.bestEdgeIncome = -1;
          return;
        }
        const newGasSpent = closestVert.gasSpent + gas;
        const price = v2.price / finish.price;
        const gasPrice = v2.gasPrice * price;
        const newTotal = newIncome * price - newGasSpent * gasPrice;
        console.assert(e.bestEdgeIncome === 0, "Error 373");
        e.bestEdgeIncome = newIncome * price;
        e.spentGasNew = e.spentGas + gas;
        if (!v2.bestSource)
          nextVertList.push(v2);
        if (!v2.bestSource || newTotal > v2.bestTotal) {
          DEBUG(() => {
            const st = closestVert?.token == from ? "*" : "";
            const fn = v2?.token == to ? "*" : "";
            debug_info += `${st}${closestVert?.token.name}->${v2.token.name}${fn} ${v2.bestIncome} -> ${newIncome}
`;
          });
          v2.bestIncome = newIncome;
          v2.gasSpent = newGasSpent;
          v2.bestTotal = newTotal;
          v2.bestSource = e;
        }
      });
      processedVert.add(closestVert);
    }
  }
  findBestPathExactOut(from, to, amountOut) {
    const start = this.getVert(to);
    const finish = this.getVert(from);
    if (!start || !finish)
      return;
    this.edges.forEach((e) => {
      e.bestEdgeIncome = 0;
      e.spentGasNew = 0;
    });
    this.vertices.forEach((v) => {
      v.bestIncome = 0;
      v.gasSpent = 0;
      v.bestTotal = 0;
      v.bestSource = void 0;
      v.checkLine = -1;
    });
    start.bestIncome = amountOut;
    start.bestTotal = amountOut;
    const processedVert = /* @__PURE__ */ new Set();
    const nextVertList = [start];
    let debug_info = "";
    let checkLine = 0;
    for (; ; ) {
      let closestVert;
      let closestTotal;
      let closestPosition = 0;
      nextVertList.forEach((v, i) => {
        if (closestTotal === void 0 || v.bestTotal < closestTotal) {
          closestTotal = v.bestTotal;
          closestVert = v;
          closestPosition = i;
        }
      });
      if (!closestVert)
        return;
      closestVert.checkLine = checkLine++;
      if (closestVert === finish) {
        const bestPath = [];
        for (let v = finish; v?.bestSource; v = v.getNeibour(v.bestSource)) {
          bestPath.push(v.bestSource);
        }
        DEBUG(() => console.log(debug_info));
        return {
          path: bestPath,
          input: finish.bestIncome,
          gasSpent: finish.gasSpent,
          totalInput: finish.bestTotal
        };
      }
      nextVertList.splice(closestPosition, 1);
      closestVert.edges.forEach((e) => {
        const v2 = closestVert === e.vert0 ? e.vert1 : e.vert0;
        if (processedVert.has(v2))
          return;
        let newIncome, gas;
        try {
          const { inp, gasSpent } = e.calcInput(closestVert, closestVert.bestIncome);
          if (!isFinite(inp) || !isFinite(gasSpent))
            return;
          if (inp < 0)
            return;
          newIncome = inp;
          gas = gasSpent;
        } catch (e2) {
          return;
        }
        const newGasSpent = closestVert.gasSpent + gas;
        const price = v2.price / finish.price;
        const gasPrice = v2.gasPrice * price;
        const newTotal = newIncome * price + newGasSpent * gasPrice;
        console.assert(e.bestEdgeIncome === 0, "Error 373");
        e.bestEdgeIncome = newIncome * price;
        e.spentGasNew = e.spentGas + gas;
        if (!v2.bestSource)
          nextVertList.push(v2);
        if (!v2.bestSource || newTotal < v2.bestTotal) {
          DEBUG(() => {
            const st = v2?.token == from ? "*" : "";
            const fn = closestVert?.token == to ? "*" : "";
            debug_info += `${st}${closestVert?.token.name}<-${v2.token.name}${fn} ${v2.bestIncome} -> ${newIncome}
`;
          });
          v2.bestIncome = newIncome;
          v2.gasSpent = newGasSpent;
          v2.bestTotal = newTotal;
          v2.bestSource = e;
        }
      });
      processedVert.add(closestVert);
    }
  }
  addPath(from, to, path) {
    let _from = from;
    path.forEach((e) => {
      if (_from) {
        e.applySwap(_from);
        _from = _from.getNeibour(e);
      } else {
        console.error("Unexpected 315");
      }
    });
    ASSERT(() => {
      const res = this.vertices.every((v) => {
        let total = 0;
        let totalModule = 0;
        v.edges.forEach((e) => {
          if (e.vert0 === v) {
            if (e.direction) {
              total -= e.amountInPrevious;
            } else {
              total += e.amountInPrevious;
            }
            totalModule += e.amountInPrevious;
          } else {
            if (e.direction) {
              total += e.amountOutPrevious;
            } else {
              total -= e.amountOutPrevious;
            }
            totalModule += e.amountOutPrevious;
          }
        });
        if (v === from)
          return total <= 0;
        if (v === to)
          return total >= 0;
        if (totalModule === 0)
          return total === 0;
        return Math.abs(total / totalModule) < 1e10;
      });
      return res;
    }, "Error 290");
  }
  getPrimaryPriceForPath(from, path) {
    let p = 1;
    let prevToken = from;
    path.forEach((edge) => {
      const direction = edge.vert0 === prevToken;
      const edgePrice = edge.pool.calcCurrentPriceWithoutFee(direction);
      p *= edgePrice;
      prevToken = prevToken.getNeibour(edge);
    });
    return p;
  }
  findBestRouteExactIn(from, to, amountIn, mode) {
    let amountInBN;
    if (amountIn instanceof BigNumber9) {
      amountInBN = amountIn;
      amountIn = parseInt(amountIn.toString());
    } else {
      amountInBN = getBigNumber(amountIn);
    }
    let routeValues = [];
    if (Array.isArray(mode)) {
      const sum = mode.reduce((a, b) => a + b, 0);
      routeValues = mode.map((e) => e / sum);
    } else {
      for (let i = 0; i < mode; ++i)
        routeValues.push(1 / mode);
    }
    this.edges.forEach((e) => {
      e.amountInPrevious = 0;
      e.amountOutPrevious = 0;
      e.direction = true;
    });
    let output = 0;
    let gasSpentInit = 0;
    let totalOutput = 0;
    let totalrouted = 0;
    let primaryPrice;
    let step;
    for (step = 0; step < routeValues.length; ++step) {
      const p = this.findBestPathExactIn(from, to, amountIn * routeValues[step]);
      if (!p) {
        break;
      } else {
        output += p.output;
        gasSpentInit += p.gasSpent;
        totalOutput += p.totalOutput;
        this.addPath(this.getVert(from), this.getVert(to), p.path);
        totalrouted += routeValues[step];
      }
    }
    if (step == 0 || output == 0)
      return {
        status: "NoWay" /* NoWay */,
        fromToken: from,
        toToken: to,
        amountIn: 0,
        amountInBN: BigNumber9.from(0),
        amountOut: 0,
        amountOutBN: BigNumber9.from(0),
        legs: [],
        gasSpent: 0,
        totalAmountOut: 0,
        totalAmountOutBN: BigNumber9.from(0)
      };
    let status;
    if (step < routeValues.length)
      status = "Partial" /* Partial */;
    else
      status = "Success" /* Success */;
    const removedEdgesNumber = this.removeEdgesWithLowFlow(1e-3);
    const fromVert = this.getVert(from);
    const toVert = this.getVert(to);
    const { legs, gasSpent, topologyWasChanged } = this.getRouteLegs(fromVert, toVert);
    console.assert(gasSpent <= gasSpentInit, "Internal Error 491");
    if (topologyWasChanged || removedEdgesNumber > 0) {
      output = this.updateLegsInOut(legs, amountIn);
      totalOutput = output - toVert.gasPrice * gasSpent;
    }
    let swapPrice, priceImpact;
    try {
      swapPrice = output / amountIn;
      const priceTo = this.getVert(to)?.price;
      const priceFrom = this.getVert(from)?.price;
      primaryPrice = priceTo && priceFrom ? priceFrom / priceTo : void 0;
      priceImpact = primaryPrice !== void 0 ? 1 - swapPrice / primaryPrice : void 0;
    } catch (e) {
    }
    return {
      status,
      fromToken: from,
      toToken: to,
      primaryPrice,
      swapPrice,
      priceImpact,
      amountIn: amountIn * totalrouted,
      amountInBN: status == "Success" /* Success */ ? amountInBN : getBigNumber(amountIn * totalrouted),
      amountOut: output,
      amountOutBN: getBigNumber(output),
      legs,
      gasSpent,
      totalAmountOut: totalOutput,
      totalAmountOutBN: getBigNumber(totalOutput)
    };
  }
  findBestRouteExactOut(from, to, amountOut, mode) {
    let routeValues = [];
    if (Array.isArray(mode)) {
      const sum = mode.reduce((a, b) => a + b, 0);
      routeValues = mode.map((e) => e / sum);
    } else {
      for (let i = 0; i < mode; ++i)
        routeValues.push(1 / mode);
    }
    this.edges.forEach((e) => {
      e.amountInPrevious = 0;
      e.amountOutPrevious = 0;
      e.direction = true;
    });
    let input = 0;
    let gasSpentInit = 0;
    let totalrouted = 0;
    let primaryPrice;
    let step;
    for (step = 0; step < routeValues.length; ++step) {
      const p = this.findBestPathExactOut(from, to, amountOut * routeValues[step]);
      if (!p) {
        break;
      } else {
        input += p.input;
        gasSpentInit += p.gasSpent;
        this.addPath(this.getVert(from), this.getVert(to), p.path);
        totalrouted += routeValues[step];
      }
    }
    if (step == 0)
      return {
        status: "NoWay" /* NoWay */,
        fromToken: from,
        toToken: to,
        amountIn: 0,
        amountInBN: BigNumber9.from(0),
        amountOut: 0,
        amountOutBN: BigNumber9.from(0),
        legs: [],
        gasSpent: 0,
        totalAmountOut: 0,
        totalAmountOutBN: BigNumber9.from(0)
      };
    let status;
    if (step < routeValues.length)
      status = "Partial" /* Partial */;
    else
      status = "Success" /* Success */;
    const removedEdgesNumber = this.removeEdgesWithLowFlow(1e-3);
    const fromVert = this.getVert(from);
    const toVert = this.getVert(to);
    const { legs, gasSpent, topologyWasChanged } = this.getRouteLegs(fromVert, toVert);
    console.assert(gasSpent <= gasSpentInit, "Internal Error 491");
    if (topologyWasChanged || removedEdgesNumber > 0) {
      input = this.calcLegsAmountIn(legs, amountOut);
    }
    let swapPrice, priceImpact;
    try {
      swapPrice = amountOut / input;
      const priceTo = this.getVert(to)?.price;
      const priceFrom = this.getVert(from)?.price;
      primaryPrice = priceTo && priceFrom ? priceFrom / priceTo : void 0;
      priceImpact = primaryPrice !== void 0 ? 1 - swapPrice / primaryPrice : void 0;
    } catch (e) {
    }
    return {
      status,
      fromToken: from,
      toToken: to,
      primaryPrice,
      swapPrice,
      priceImpact,
      amountIn: input,
      amountInBN: getBigNumber(input),
      amountOut: amountOut * totalrouted,
      amountOutBN: getBigNumber(amountOut * totalrouted),
      legs,
      gasSpent,
      totalAmountOut: amountOut - gasSpent * toVert.gasPrice,
      totalAmountOutBN: getBigNumber(amountOut - gasSpent * toVert.gasPrice)
    };
  }
  getRouteLegs(from, to) {
    const { vertices, topologyWasChanged } = this.cleanTopology(from, to);
    const legs = [];
    let gasSpent = 0;
    vertices.forEach((n) => {
      const outEdges = n.getOutputEdges().map((e) => {
        const from2 = this.edgeFrom(e);
        return from2 ? [e, from2.vert, from2.amount] : [e];
      });
      let outAmount = outEdges.reduce((a, b) => a + b[2], 0);
      if (outAmount <= 0)
        return;
      const total = outAmount;
      outEdges.forEach((e, i) => {
        const p = e[2];
        const quantity = i + 1 === outEdges.length ? 1 : p / outAmount;
        const edge = e[0];
        const poolType = edge.pool instanceof StableSwapRPool ? "Stable" : edge.pool instanceof ConstantProductRPool ? "Classic" : "Unknown";
        legs.push({
          poolAddress: edge.pool.address,
          poolType,
          poolFee: edge.pool.fee,
          tokenFrom: n.token,
          tokenTo: n.getNeibour(edge).token,
          assumedAmountIn: edge.direction ? edge.amountInPrevious : edge.amountOutPrevious,
          assumedAmountOut: edge.direction ? edge.amountOutPrevious : edge.amountInPrevious,
          swapPortion: quantity,
          absolutePortion: p / total
        });
        gasSpent += e[0].pool.swapGasCost;
        outAmount -= p;
      });
      console.assert(outAmount / total < 1e-12, "Error 281");
    });
    return { legs, gasSpent, topologyWasChanged };
  }
  edgeFrom(e) {
    if (e.amountInPrevious === 0)
      return void 0;
    return e.direction ? { vert: e.vert0, amount: e.amountInPrevious } : { vert: e.vert1, amount: e.amountOutPrevious };
  }
  removeEdgesWithLowFlow(minFraction) {
    const weakEdgeList = [];
    this.vertices.forEach((v) => {
      const outEdges = v.getOutputEdges();
      if (outEdges.length <= 1)
        return;
      const amounts = outEdges.map((e) => {
        const data = this.edgeFrom(e);
        if (data !== void 0)
          return data.amount;
        console.error("Tines: Internal Error 1123");
      });
      const totalOut = amounts.reduce((a, b) => a += b, 0);
      outEdges.forEach((e, i) => {
        if (amounts[i] / totalOut < minFraction)
          weakEdgeList.push(e);
      });
    });
    weakEdgeList.forEach((e) => e.canBeUsed = false);
    return weakEdgeList.length;
  }
  updateLegsInOut(legs, amountIn) {
    const amounts = /* @__PURE__ */ new Map();
    amounts.set(legs[0].tokenFrom.tokenId, amountIn);
    legs.forEach((l) => {
      const vert = this.getVert(l.tokenFrom);
      console.assert(vert !== void 0, "Internal Error 570");
      const edge = vert.edges.find((e) => e.pool.address === l.poolAddress);
      console.assert(edge !== void 0, "Internel Error 569");
      const pool = edge.pool;
      const direction = vert === edge.vert0;
      const inputTotal = amounts.get(l.tokenFrom.tokenId);
      console.assert(inputTotal !== void 0, "Internal Error 564");
      const input = inputTotal * l.swapPortion;
      amounts.set(l.tokenFrom.tokenId, inputTotal - input);
      const output = pool.calcOutByIn(input, direction).out;
      const vertNext = vert.getNeibour(edge);
      const prevAmount = amounts.get(vertNext.token.tokenId);
      amounts.set(vertNext.token.tokenId, (prevAmount || 0) + output);
      l.assumedAmountIn = input;
      l.assumedAmountOut = output;
    });
    return amounts.get(legs[legs.length - 1].tokenTo.tokenId) || 0;
  }
  calcLegsAmountIn(legs, amountOut) {
    const totalOutputAssumed = /* @__PURE__ */ new Map();
    legs.forEach((l) => {
      const prevValue = totalOutputAssumed.get(l.tokenFrom.tokenId) || 0;
      totalOutputAssumed.set(l.tokenFrom.tokenId, prevValue + l.assumedAmountOut);
    });
    const amounts = /* @__PURE__ */ new Map();
    amounts.set(legs[legs.length - 1].tokenTo.tokenId, amountOut);
    for (let i = legs.length - 1; i >= 0; --i) {
      const l = legs[i];
      const vert = this.getVert(l.tokenTo);
      console.assert(vert !== void 0, "Internal Error 884");
      const edge = vert.edges.find((e) => e.pool.address === l.poolAddress);
      console.assert(edge !== void 0, "Internel Error 888");
      const pool = edge.pool;
      const direction = vert === edge.vert1;
      const outputTotal = amounts.get(l.tokenTo.tokenId);
      console.assert(outputTotal !== void 0, "Internal Error 893");
      const totalAssumed = totalOutputAssumed.get(l.tokenFrom.tokenId);
      console.assert(totalAssumed !== void 0, "Internal Error 903");
      const output = outputTotal * l.assumedAmountOut / totalAssumed;
      const input = pool.calcInByOut(output, direction).inp;
      const vertNext = vert.getNeibour(edge);
      const prevAmount = amounts.get(vertNext.token.tokenId);
      amounts.set(vertNext.token.tokenId, (prevAmount || 0) + input);
    }
    return amounts.get(legs[0].tokenFrom.tokenId) || 0;
  }
  cleanTopology(from, to) {
    let topologyWasChanged = false;
    let result = this.topologySort(from, to);
    if (result.status !== 2) {
      topologyWasChanged = true;
      while (result.status === 0) {
        this.removeWeakestEdge(result.vertices);
        result = this.topologySort(from, to);
      }
      if (result.status === 3) {
        this.removeDeadEnds(result.vertices);
        result = this.topologySort(from, to);
      }
      console.assert(result.status === 2, "Internal Error 563");
      if (result.status !== 2)
        return { vertices: [], topologyWasChanged };
    }
    return { vertices: result.vertices, topologyWasChanged };
  }
  removeDeadEnds(verts) {
    verts.forEach((v) => {
      v.getInputEdges().forEach((e) => {
        e.canBeUsed = false;
      });
    });
  }
  removeWeakestEdge(verts) {
    let minVert, minVertNext;
    let minOutput = Number.MAX_VALUE;
    verts.forEach((v1, i) => {
      const v2 = i === 0 ? verts[verts.length - 1] : verts[i - 1];
      let out = 0;
      v1.getOutputEdges().forEach((e) => {
        if (v1.getNeibour(e) !== v2)
          return;
        out += e.direction ? e.amountOutPrevious : e.amountInPrevious;
      });
      if (out < minOutput) {
        minVert = v1;
        minVertNext = v2;
        minOutput = out;
      }
    });
    minVert.getOutputEdges().forEach((e) => {
      if (minVert.getNeibour(e) !== minVertNext)
        return;
      e.canBeUsed = false;
    });
  }
  topologySort(from, to) {
    const vertState = /* @__PURE__ */ new Map();
    const vertsFinished = [];
    const foundCycle = [];
    const foundDeadEndVerts = [];
    function topSortRecursive(current) {
      const state = vertState.get(current);
      if (state === 2 || state === 3)
        return state;
      if (state === 1) {
        console.assert(foundCycle.length == 0, "Internal Error 566");
        foundCycle.push(current);
        return 1;
      }
      vertState.set(current, 1);
      let successors2Exist = false;
      const outEdges = current.getOutputEdges();
      for (let i = 0; i < outEdges.length; ++i) {
        const e = outEdges[i];
        const res2 = topSortRecursive(current.getNeibour(e));
        if (res2 === 0)
          return 0;
        if (res2 === 1) {
          if (foundCycle[0] === current)
            return 0;
          else {
            foundCycle.push(current);
            return 1;
          }
        }
        if (res2 === 2)
          successors2Exist = true;
      }
      if (successors2Exist) {
        console.assert(current !== to, "Internal Error 589");
        vertsFinished.push(current);
        vertState.set(current, 2);
        return 2;
      } else {
        if (current !== to) {
          foundDeadEndVerts.push(current);
          vertState.set(current, 3);
          return 3;
        }
        vertsFinished.push(current);
        vertState.set(current, 2);
        return 2;
      }
    }
    const res = topSortRecursive(from);
    if (res === 0)
      return { status: 0, vertices: foundCycle };
    if (foundDeadEndVerts.length)
      return { status: 3, vertices: foundDeadEndVerts };
    ASSERT(() => {
      if (vertsFinished[0] !== to)
        return false;
      if (vertsFinished[vertsFinished.length - 1] !== from)
        return false;
      return true;
    }, "Internal Error 614");
    if (res === 2)
      return { status: 2, vertices: vertsFinished.reverse() };
    console.assert(true, "Internal Error 612");
    return { status: 1, vertices: [] };
  }
};

// src/MultiRouter.ts
import { BigNumber as BigNumber10 } from "@ethersproject/bignumber";
function calcPriceImactWithoutFee(route) {
  if (route.primaryPrice === void 0 || route.swapPrice === void 0) {
    return void 0;
  } else {
    let oneMinusCombinedFee = 1;
    route.legs.forEach((l) => oneMinusCombinedFee *= 1 - l.poolFee);
    return Math.max(0, 1 - route.swapPrice / route.primaryPrice / oneMinusCombinedFee);
  }
}
var defaultFlowNumber = 12;
var maxFlowNumber = 100;
function calcBestFlowNumber(bestSingleRoute, amountIn, gasPriceIn) {
  if (amountIn instanceof BigNumber10) {
    amountIn = parseInt(amountIn.toString());
  }
  const priceImpact = calcPriceImactWithoutFee(bestSingleRoute);
  if (!priceImpact)
    return defaultFlowNumber;
  const bestFlowAmount = Math.sqrt(bestSingleRoute.gasSpent * (gasPriceIn || 0) * amountIn / priceImpact);
  const bestFlowNumber = Math.round(amountIn / bestFlowAmount);
  if (!isFinite(bestFlowNumber))
    return maxFlowNumber;
  const realFlowNumber = Math.max(1, Math.min(bestFlowNumber, maxFlowNumber));
  return realFlowNumber;
}
function getBetterRouteExactIn(route1, route2) {
  if (route1.status == "NoWay" /* NoWay */)
    return route2;
  if (route2.status == "NoWay" /* NoWay */)
    return route1;
  if (route1.status == "Partial" /* Partial */ && route2.status == "Success" /* Success */)
    return route2;
  if (route2.status == "Partial" /* Partial */ && route1.status == "Success" /* Success */)
    return route1;
  return route1.totalAmountOut > route2.totalAmountOut ? route1 : route2;
}
function deduplicatePools(pools) {
  const poolMap = /* @__PURE__ */ new Map();
  pools.forEach((p) => {
    const chId0 = p.token0.chainId || 0;
    const chId1 = p.token1.chainId || 0;
    const chainInfo = chId0 < chId1 ? `_${chId0}_${chId1}` : `_${chId1}_${chId0}`;
    poolMap.set(p.address + chainInfo, p);
  });
  return Array.from(poolMap.values());
}
function findMultiRouteExactIn(from, to, amountIn, pools, baseTokenOrNetworks, gasPrice, flows) {
  pools = deduplicatePools(pools);
  checkChainId(pools, baseTokenOrNetworks);
  setTokenId(from, to);
  const g = new Graph(pools, from, baseTokenOrNetworks, gasPrice);
  if (flows !== void 0)
    return g.findBestRouteExactIn(from, to, amountIn, flows);
  const outSingle = g.findBestRouteExactIn(from, to, amountIn, 1);
  g.cleanTmpData();
  const bestFlowNumber = calcBestFlowNumber(outSingle, amountIn, g.getVert(from)?.gasPrice);
  if (bestFlowNumber === 1)
    return outSingle;
  const outMulti = g.findBestRouteExactIn(from, to, amountIn, bestFlowNumber);
  return getBetterRouteExactIn(outSingle, outMulti);
}
function getBetterRouteExactOut(route1, route2, gasPrice) {
  if (route1.status == "NoWay" /* NoWay */)
    return route2;
  if (route2.status == "NoWay" /* NoWay */)
    return route1;
  if (route1.status == "Partial" /* Partial */ && route2.status == "Success" /* Success */)
    return route2;
  if (route2.status == "Partial" /* Partial */ && route1.status == "Success" /* Success */)
    return route1;
  const totalAmountIn1 = route1.amountIn + route1.gasSpent * gasPrice;
  const totalAmountIn2 = route2.amountIn + route2.gasSpent * gasPrice;
  return totalAmountIn1 < totalAmountIn2 ? route1 : route2;
}
function findMultiRouteExactOut(from, to, amountOut, pools, baseTokenOrNetworks, gasPrice, flows) {
  pools = deduplicatePools(pools);
  checkChainId(pools, baseTokenOrNetworks);
  setTokenId(from, to);
  if (amountOut instanceof BigNumber10) {
    amountOut = parseInt(amountOut.toString());
  }
  const g = new Graph(pools, from, baseTokenOrNetworks, gasPrice);
  if (flows !== void 0)
    return g.findBestRouteExactOut(from, to, amountOut, flows);
  const inSingle = g.findBestRouteExactOut(from, to, amountOut, 1);
  g.cleanTmpData();
  const fromV = g.getVert(from);
  const bestFlowNumber = calcBestFlowNumber(inSingle, inSingle.amountIn, fromV?.gasPrice);
  if (bestFlowNumber === 1)
    return inSingle;
  const inMulti = g.findBestRouteExactOut(from, to, amountOut, bestFlowNumber);
  return getBetterRouteExactOut(inSingle, inMulti, fromV?.gasPrice || 0);
}
function findSingleRouteExactIn(from, to, amountIn, pools, baseTokenOrNetworks, gasPrice) {
  pools = deduplicatePools(pools);
  checkChainId(pools, baseTokenOrNetworks);
  setTokenId(from, to);
  const g = new Graph(pools, from, baseTokenOrNetworks, gasPrice);
  const out = g.findBestRouteExactIn(from, to, amountIn, 1);
  return out;
}
function findSingleRouteExactOut(from, to, amountOut, pools, baseTokenOrNetworks, gasPrice) {
  pools = deduplicatePools(pools);
  checkChainId(pools, baseTokenOrNetworks);
  setTokenId(from, to);
  const g = new Graph(pools, from, baseTokenOrNetworks, gasPrice);
  if (amountOut instanceof BigNumber10) {
    amountOut = parseInt(amountOut.toString());
  }
  const out = g.findBestRouteExactOut(from, to, amountOut, 1);
  return out;
}
function calcTokenPrices(pools, baseToken, minPriceLiquidity = 0) {
  setTokenId(baseToken);
  const g = new Graph(pools, baseToken, baseToken, 0, minPriceLiquidity);
  const res = /* @__PURE__ */ new Map();
  g.vertices.forEach((v) => {
    if (v.price !== 0)
      res.set(v.token, v.price);
  });
  return res;
}
function checkChainId(pools, baseTokenOrNetworks) {
  if (baseTokenOrNetworks instanceof Array) {
    baseTokenOrNetworks.forEach((n) => {
      if (n.chainId !== n.baseToken.chainId) {
        throw new Error(`Chain '${n.chainId}' has baseToken with '${n.baseToken.chainId}' that are not the same`);
      }
    });
  }
  const chainIds = baseTokenOrNetworks instanceof Array ? baseTokenOrNetworks.map((n) => n.chainId) : [baseTokenOrNetworks.chainId];
  const chainIdSet = new Set(chainIds);
  const checkToken = (t) => {
    if (!chainIdSet.has(t.chainId)) {
      throw new Error(
        `Token ${t.name}/${t.address} chainId='${t.chainId}' is not in list of possible chains: [${chainIds.join(
          ", "
        )}]`
      );
    }
  };
  pools.forEach((p) => {
    checkToken(p.token0);
    checkToken(p.token1);
  });
}

// src/StarGateFeesV04.ts
import { BigNumber as BigNumber11 } from "@ethersproject/bignumber";
var ZERO2 = BigNumber11.from(0);
var E14 = BigNumber11.from(10).pow(14);
var E13 = BigNumber11.from(10).pow(13);
var E17 = BigNumber11.from(10).pow(17);
var DENOMINATOR = BigNumber11.from(10).pow(18);
var DELTA_1 = BigNumber11.from(6e3).mul(E14);
var DELTA_2 = BigNumber11.from(500).mul(E14);
var LAMBDA_1 = BigNumber11.from(40).mul(E14);
var LAMBDA_2 = BigNumber11.from(9960).mul(E14);
var LP_FEE = BigNumber11.from(10).mul(E13);
var PROTOCOL_FEE = BigNumber11.from(50).mul(E13);
var PROTOCOL_SUBSIDY = BigNumber11.from(3).mul(E13);
var FIFTY_PERCENT = BigNumber11.from(5).mul(E17);
var SIXTY_PERCENT = BigNumber11.from(6).mul(E17);
function getStarGateFeesV04(state, whitelisted, amountSD) {
  const eqReward = getEqReward(state, amountSD);
  const { eqFee, protocolSubsidy } = getEquilibriumFee(state, amountSD);
  const { protocolFee, lpFee } = whitelisted ? { protocolFee: ZERO2, lpFee: ZERO2 } : getProtocolAndLpFee(state, amountSD, protocolSubsidy);
  return {
    eqFee,
    eqReward,
    lpFee,
    protocolFee
  };
}
function getEqReward(state, amountSD) {
  if (state.lpAsset.lte(state.currentAssetSD)) {
    return ZERO2;
  }
  const poolDeficit = state.lpAsset.sub(state.currentAssetSD);
  if (state.currentAssetSD.mul(100).div(state.lpAsset).lt(75) && amountSD.mul(100).gt(poolDeficit.mul(2))) {
    const eqReward = state.eqFeePool.mul(amountSD).div(poolDeficit);
    if (eqReward.gt(state.eqFeePool)) {
      return state.eqFeePool;
    }
    return eqReward;
  } else {
    return ZERO2;
  }
}
function getEquilibriumFee(state, amountSD) {
  const beforeBalance = state.currentBalance;
  const idealBalance = state.idealBalance;
  const afterBalance = beforeBalance.sub(amountSD);
  const safeZoneMax = idealBalance.mul(DELTA_1).div(DENOMINATOR);
  const safeZoneMin = idealBalance.mul(DELTA_2).div(DENOMINATOR);
  let eqFee = ZERO2;
  let protocolSubsidy = ZERO2;
  if (afterBalance.gte(safeZoneMax)) {
    eqFee = amountSD.mul(PROTOCOL_SUBSIDY).div(DENOMINATOR);
    protocolSubsidy = eqFee;
  } else if (afterBalance.gte(safeZoneMin)) {
    const proxyBeforeBalance = beforeBalance.lt(safeZoneMax) ? beforeBalance : safeZoneMax;
    eqFee = getTrapezoidArea(LAMBDA_1, ZERO2, safeZoneMax, safeZoneMin, proxyBeforeBalance, afterBalance);
  } else {
    if (beforeBalance.gte(safeZoneMin)) {
      const proxyBeforeBalance = beforeBalance.lt(safeZoneMax) ? beforeBalance : safeZoneMax;
      eqFee = eqFee.add(getTrapezoidArea(LAMBDA_1, ZERO2, safeZoneMax, safeZoneMin, proxyBeforeBalance, safeZoneMin));
      eqFee = eqFee.add(getTrapezoidArea(LAMBDA_2, LAMBDA_1, safeZoneMin, ZERO2, safeZoneMin, afterBalance));
    } else {
      eqFee = eqFee.add(getTrapezoidArea(LAMBDA_2, LAMBDA_1, safeZoneMin, ZERO2, beforeBalance, afterBalance));
    }
  }
  return { eqFee, protocolSubsidy };
}
function getProtocolAndLpFee(state, amountSD, protocolSubsidy) {
  let protocolFee = amountSD.mul(PROTOCOL_FEE).div(DENOMINATOR).sub(protocolSubsidy);
  let lpFee = amountSD.mul(LP_FEE).div(DENOMINATOR);
  if (state.allocPointIsPositive) {
    protocolFee = protocolFee.add(lpFee);
    lpFee = ZERO2;
  }
  if (state.lpAsset.isZero()) {
    return { protocolFee, lpFee };
  }
  const isAboveIdeal = state.currentBalance.sub(amountSD).gt(state.idealBalance.mul(SIXTY_PERCENT).div(DENOMINATOR));
  const currentAssetNumerated = state.currentAssetSD.mul(DENOMINATOR).div(state.lpAsset);
  if (currentAssetNumerated.lte(FIFTY_PERCENT) && isAboveIdeal) {
    protocolFee = ZERO2;
    lpFee = ZERO2;
  } else if (currentAssetNumerated.lt(SIXTY_PERCENT) && isAboveIdeal) {
    const haircut = currentAssetNumerated.sub(FIFTY_PERCENT).mul(10);
    protocolFee = protocolFee.mul(haircut).div(DENOMINATOR);
    lpFee = lpFee.mul(haircut).div(DENOMINATOR);
  }
  return { protocolFee, lpFee };
}
function getTrapezoidArea(lambda, yOffset, xUpperBound, xLowerBound, xStart, xEnd) {
  const xBoundWidth = xUpperBound.sub(xLowerBound);
  const yStart = xUpperBound.sub(xStart).mul(lambda).div(xBoundWidth).add(yOffset);
  const yEnd = xUpperBound.sub(xEnd).mul(lambda).div(xBoundWidth).add(yOffset);
  const deltaX = xStart.sub(xEnd);
  return yStart.add(yEnd).mul(deltaX).div(2).div(DENOMINATOR);
}

// src/UniV3Pool.ts
import { BigNumber as BigNumber12 } from "@ethersproject/bignumber";
var BASE_GAS_CONSUMPTION = 7e4;
var STEP_GAS_CONSUMPTION = 4e4;
var ZERO3 = BigNumber12.from(0);
var c012 = BigNumber12.from("0xfffcb933bd6fad37aa2d162d1a594001");
var c022 = BigNumber12.from("0x100000000000000000000000000000000");
var c032 = BigNumber12.from("0xfff97272373d413259a46990580e213a");
var c042 = BigNumber12.from("0xfff2e50f5f656932ef12357cf3c7fdcc");
var c052 = BigNumber12.from("0xffe5caca7e10e4e61c3624eaa0941cd0");
var c062 = BigNumber12.from("0xffcb9843d60f6159c9db58835c926644");
var c072 = BigNumber12.from("0xff973b41fa98c081472e6896dfb254c0");
var c082 = BigNumber12.from("0xff2ea16466c96a3843ec78b326b52861");
var c092 = BigNumber12.from("0xfe5dee046a99a2a811c461f1969c3053");
var c102 = BigNumber12.from("0xfcbe86c7900a88aedcffc83b479aa3a4");
var c112 = BigNumber12.from("0xf987a7253ac413176f2b074cf7815e54");
var c122 = BigNumber12.from("0xf3392b0822b70005940c7a398e4b70f3");
var c132 = BigNumber12.from("0xe7159475a2c29b7443b29c7fa6e889d9");
var c142 = BigNumber12.from("0xd097f3bdfd2022b8845ad8f792aa5825");
var c152 = BigNumber12.from("0xa9f746462d870fdf8a65dc1f90e061e5");
var c162 = BigNumber12.from("0x70d869a156d2a1b890bb3df62baf32f7");
var c172 = BigNumber12.from("0x31be135f97d08fd981231505542fcfa6");
var c182 = BigNumber12.from("0x9aa508b5b7a84e1c677de54f3e99bc9");
var c192 = BigNumber12.from("0x5d6af8dedb81196699c329225ee604");
var c202 = BigNumber12.from("0x2216e584f5fa1ea926041bedfe98");
var c212 = BigNumber12.from("0x48a170391f7dc42444e8fa2");
var max2562 = BigNumber12.from(2).pow(256).sub(1);
function getSqrtRatioAtTick2(tick) {
  const absTick = Math.abs(tick);
  let ratio = (absTick & 1) != 0 ? c012 : c022;
  if ((absTick & 2) != 0)
    ratio = ratio.mul(c032).shr(128);
  if ((absTick & 4) != 0)
    ratio = ratio.mul(c042).shr(128);
  if ((absTick & 8) != 0)
    ratio = ratio.mul(c052).shr(128);
  if ((absTick & 16) != 0)
    ratio = ratio.mul(c062).shr(128);
  if ((absTick & 32) != 0)
    ratio = ratio.mul(c072).shr(128);
  if ((absTick & 64) != 0)
    ratio = ratio.mul(c082).shr(128);
  if ((absTick & 128) != 0)
    ratio = ratio.mul(c092).shr(128);
  if ((absTick & 256) != 0)
    ratio = ratio.mul(c102).shr(128);
  if ((absTick & 512) != 0)
    ratio = ratio.mul(c112).shr(128);
  if ((absTick & 1024) != 0)
    ratio = ratio.mul(c122).shr(128);
  if ((absTick & 2048) != 0)
    ratio = ratio.mul(c132).shr(128);
  if ((absTick & 4096) != 0)
    ratio = ratio.mul(c142).shr(128);
  if ((absTick & 8192) != 0)
    ratio = ratio.mul(c152).shr(128);
  if ((absTick & 16384) != 0)
    ratio = ratio.mul(c162).shr(128);
  if ((absTick & 32768) != 0)
    ratio = ratio.mul(c172).shr(128);
  if ((absTick & 65536) != 0)
    ratio = ratio.mul(c182).shr(128);
  if ((absTick & 131072) != 0)
    ratio = ratio.mul(c192).shr(128);
  if ((absTick & 262144) != 0)
    ratio = ratio.mul(c202).shr(128);
  if ((absTick & 524288) != 0)
    ratio = ratio.mul(c212).shr(128);
  if (tick > 0)
    ratio = max2562.div(ratio);
  return ratio.shr(32);
}
var two962 = Math.pow(2, 96);
var UniV3Pool = class extends RPool {
  liquidity;
  sqrtPriceX96;
  nearestTick;
  ticks;
  constructor(address, token0, token1, fee, reserve0, reserve1, tick, liquidity, sqrtPriceX96, ticks) {
    super(address, token0, token1, fee, reserve0, reserve1, TYPICAL_MINIMAL_LIQUIDITY, TYPICAL_SWAP_GAS_COST);
    this.ticks = ticks;
    if (this.ticks.length === 0) {
      this.ticks.push({ index: CL_MIN_TICK, DLiquidity: ZERO3 });
      this.ticks.push({ index: CL_MAX_TICK, DLiquidity: ZERO3 });
    }
    if (this.ticks[0].index > CL_MIN_TICK)
      this.ticks.unshift({ index: CL_MIN_TICK, DLiquidity: ZERO3 });
    if (this.ticks[this.ticks.length - 1].index < CL_MAX_TICK)
      this.ticks.push({ index: CL_MAX_TICK, DLiquidity: ZERO3 });
    this.liquidity = liquidity;
    this.sqrtPriceX96 = sqrtPriceX96;
    this.nearestTick = this._findTickForPrice(tick);
  }
  updateState(reserve0, reserve1, tick, liquidity, sqrtPriceX96) {
    this.updateReserves(reserve0, reserve1);
    this.liquidity = liquidity;
    this.sqrtPriceX96 = sqrtPriceX96;
    this.nearestTick = this._findTickForPrice(tick);
  }
  _findTickForPrice(tick) {
    let a = 0;
    let b = this.ticks.length;
    while (b - a > 1) {
      const c = Math.floor((a + b) / 2);
      const ind = this.ticks[c].index;
      if (ind == tick)
        return c;
      if (ind < tick)
        a = c;
      else
        b = c;
    }
    return a;
  }
  calcOutByIn(amountIn, direction) {
    let nextTickToCross = direction ? this.nearestTick : this.nearestTick + 1;
    const currentPriceBN = this.sqrtPriceX96;
    let currentPrice = parseInt(currentPriceBN.toString()) / two962;
    let currentLiquidityBN = this.liquidity;
    let outAmount = 0;
    let input = amountIn * (1 - this.fee);
    let stepCounter = 0;
    let startFlag = true;
    while (input > 0) {
      if (nextTickToCross < 0 || nextTickToCross >= this.ticks.length) {
        throw "UniV3 OutOfLiquidity";
      }
      let nextTickPrice, priceDiff;
      if (startFlag) {
        const nextTickPriceBN = getSqrtRatioAtTick2(this.ticks[nextTickToCross].index);
        nextTickPrice = parseInt(nextTickPriceBN.toString()) / two962;
        priceDiff = parseInt(currentPriceBN.sub(nextTickPriceBN).toString()) / two962;
        startFlag = false;
      } else {
        nextTickPrice = Math.sqrt(Math.pow(1.0001, this.ticks[nextTickToCross].index));
        priceDiff = currentPrice - nextTickPrice;
      }
      let output = 0;
      const currentLiquidity = parseInt(currentLiquidityBN.toString());
      if (direction) {
        const maxDx = currentLiquidity * priceDiff / currentPrice / nextTickPrice;
        if (input <= maxDx) {
          output = currentLiquidity * currentPrice * input / (input + currentLiquidity / currentPrice);
          input = 0;
        } else {
          output = currentLiquidity * priceDiff;
          currentPrice = nextTickPrice;
          input -= maxDx;
          currentLiquidityBN = currentLiquidityBN.sub(this.ticks[nextTickToCross].DLiquidity);
          nextTickToCross--;
          if (nextTickToCross == 0)
            currentLiquidityBN = ZERO3;
        }
      } else {
        const maxDy = currentLiquidity * -priceDiff;
        if (input <= maxDy) {
          output = input / currentPrice / (currentPrice + input / currentLiquidity);
          input = 0;
        } else {
          output = currentLiquidity * -priceDiff / currentPrice / nextTickPrice;
          currentPrice = nextTickPrice;
          input -= maxDy;
          currentLiquidityBN = currentLiquidityBN.add(this.ticks[nextTickToCross].DLiquidity);
          nextTickToCross++;
          if (nextTickToCross == this.ticks.length - 1)
            currentLiquidityBN = ZERO3;
        }
      }
      outAmount += output;
      ++stepCounter;
    }
    return { out: outAmount, gasSpent: BASE_GAS_CONSUMPTION + STEP_GAS_CONSUMPTION * stepCounter };
  }
  calcInByOut(amountOut, direction) {
    let nextTickToCross = direction ? this.nearestTick : this.nearestTick + 1;
    const currentPriceBN = this.sqrtPriceX96;
    let currentPrice = parseInt(currentPriceBN.toString()) / two962;
    let currentLiquidityBN = this.liquidity;
    let input = 0;
    let outBeforeFee = amountOut;
    let stepCounter = 0;
    let startFlag = true;
    while (outBeforeFee > 0) {
      if (nextTickToCross < 0 || nextTickToCross >= this.ticks.length)
        return { inp: Number.POSITIVE_INFINITY, gasSpent: this.swapGasCost };
      ++stepCounter;
      let nextTickPrice, priceDiff;
      if (startFlag) {
        const nextTickPriceBN = getSqrtRatioAtTick2(this.ticks[nextTickToCross].index);
        nextTickPrice = parseInt(nextTickPriceBN.toString()) / two962;
        priceDiff = parseInt(currentPriceBN.sub(nextTickPriceBN).toString()) / two962;
        startFlag = false;
      } else {
        nextTickPrice = Math.sqrt(Math.pow(1.0001, this.ticks[nextTickToCross].index));
        priceDiff = currentPrice - nextTickPrice;
      }
      const currentLiquidity = parseInt(currentLiquidityBN.toString());
      if (direction) {
        const maxDy = currentLiquidity * priceDiff;
        if (outBeforeFee <= maxDy) {
          input += outBeforeFee / currentPrice / (currentPrice - outBeforeFee / currentLiquidity);
          outBeforeFee = 0;
        } else {
          input += currentLiquidity * priceDiff / currentPrice / nextTickPrice;
          currentPrice = nextTickPrice;
          outBeforeFee -= maxDy;
          currentLiquidityBN = currentLiquidityBN.sub(this.ticks[nextTickToCross].DLiquidity);
          nextTickToCross--;
          if (nextTickToCross == 0)
            currentLiquidityBN = ZERO3;
        }
      } else {
        const maxDx = currentLiquidity * -priceDiff / currentPrice / nextTickPrice;
        if (outBeforeFee <= maxDx) {
          input += currentLiquidity * currentPrice * outBeforeFee / (currentLiquidity / currentPrice - outBeforeFee);
          outBeforeFee = 0;
        } else {
          input += currentLiquidity * -priceDiff;
          currentPrice = nextTickPrice;
          outBeforeFee -= maxDx;
          currentLiquidityBN = currentLiquidityBN.add(this.ticks[nextTickToCross].DLiquidity);
          nextTickToCross++;
          if (nextTickToCross == this.ticks.length - 1)
            currentLiquidityBN = ZERO3;
        }
      }
    }
    return { inp: input / (1 - this.fee), gasSpent: BASE_GAS_CONSUMPTION + STEP_GAS_CONSUMPTION * stepCounter };
  }
  calcCurrentPriceWithoutFee(direction) {
    const currentPrice = parseInt(this.sqrtPriceX96.toString()) / two962;
    const p = currentPrice * currentPrice;
    return direction ? p : 1 / p;
  }
};
export {
  ASSERT,
  BridgeBento,
  BridgeUnlimited,
  CLRPool,
  CL_MAX_TICK,
  CL_MIN_TICK,
  ConstantProductRPool,
  CurvePool,
  DEBUG,
  DEBUG_MODE_ON,
  Edge,
  Graph,
  HybridComputeLiquidity,
  HybridRPool,
  HybridgetY,
  OutOfLiquidity,
  Pool,
  PoolType,
  RConcentratedLiquidityPool,
  RConstantProductPool,
  RHybridPool,
  RPool,
  RWeightedPool,
  RouteStatus,
  StableSwapRPool,
  TYPICAL_MINIMAL_LIQUIDITY,
  TYPICAL_SWAP_GAS_COST,
  UniV3Pool,
  Vertice,
  adjustedReservesToReal,
  calcInByOut,
  calcInputByPrice,
  calcOutByIn,
  calcPrice,
  calcSquareEquation,
  calcTokenPrices,
  closeValues,
  findMultiRouteExactIn,
  findMultiRouteExactOut,
  findSingleRouteExactIn,
  findSingleRouteExactOut,
  getBigNumber,
  getStarGateFeesV04,
  realReservesToAdjusted,
  revertPositive,
  setTokenId,
  toAmountBN,
  toShareBN
};
